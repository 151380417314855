import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import loader from "../../Assets/Images/loader/ripple.svg";

const BetReturnAmount = () => {

    const [input, setInput] = useState([])
    const [isLoading, setIsLoading]=useState(false)
    useEffect(() => {
        getCategories()
    }, []);


    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const [showRoundList, setShowRoundList] = useState('none')
    const [roundAreaCss, setRoundAreaCss] = useState('none')
    useEffect(() => {
        showList()
    }, []);
    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')
    const [searchBtn, setsearchBtn] = useState('none')

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

        if (e.target.name == 'category_id') {            
            if (e.target.value == 1) {
                setsearchBtn('block')
                setShowFrSr('none')
                setRoundAreaCss('none')
                showList()
                setShowRoundList('block')
                setInput(prevState => ({...prevState, round_show_id: 0, area_round_id: 0}))
            }
            else if (e.target.value == 2) {
                setsearchBtn('block')
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
                setInput(prevState => ({...prevState, area_round_id: 0, frsr_id: 0}))
            }
            else if (e.target.value == 3) {
                setsearchBtn('block')
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('none')
                setInput(prevState => ({...prevState, area_round_id: 25}))
            } else if (e.target.value == 0) {
                setsearchBtn('none')
                setShowRoundList('none')
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'round_show_id') {
            if (e.target.value != 0) {
                getShowArea(e.target.value)
                setRoundAreaCss('block')
            } else {
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'area_round_id' && input.category_id == 2) {
            if (e.target.value != 0) {
                getFrSr(e.target.value)
                setShowFrSr('block')
            } else {
                setShowFrSr('none')
            }
        }

    }
    const getFrSr = (id) => {
        axios.get('/api/dashboard/get-area-frsr/' + id).then(res => {
            setFrSr(res.data)
        })
    }

    const getShowArea = (id) => {
        axios.get('/api/dashboard/get-round-list/1/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [round_areas, set_round_areas] = useState([])
    const getRoundArea = (id) => {
        axios.get('/api/dashboard/get-round-area/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }
    
    // const [Datas, setDatas] = useState([])
    const [Numbers, setNumbers] = useState([])
    const [Colors, setColors] = useState([])
    const [House, setHouse] = useState([])
    const [Ending, setEnding] = useState([])
    
    const getNumbers = () => {
        setIsLoading(true)
        axios.post('/api/dashboard/numbers-orders', input).then(res => {
            // setDatas(res.data.data)
            
            setNumbers(res.data.data.numbers);
            setColors(res.data.data.colors);
            setHouse(res.data.data.house_ending.house);
            setEnding(res.data.data.house_ending.ending);

            setIsLoading(false)
        })
    }

    return (
        <div>
            <>
                <Helmet>
                    <title>Return Bet | Teer-125</title>
                </Helmet>

                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="top-selling-table-section">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>Bet Return Amount</h5>
                            </div>
                            <div className="row align-items-end position-relative">
                                {isLoading ?
                                    <div className={'table-data-loader'}>
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:null
                                }
                                <div className="col-md-2 mb-2">
                                    <label htmlFor="inputEmail3" className="col-form-label">Select Category</label>
                                    <select
                                        name={'category_id'}
                                        value={input.category_id}
                                        onChange={handleInput}
                                        className="form-select"
                                    >
                                        <option value={0}>Select Category</option>
                                        {categories.map((category, index) => (
                                            <option value={category.id} key={index}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2 mb-2" style={{display: showRoundList}}>
                                    <label htmlFor="inputEmail3" className="col-form-label">Select Show</label>
                                    <select
                                        name={'round_show_id'}
                                        value={input.round_show_id}
                                        onChange={handleInput}
                                        className="form-select"
                                    >
                                        <option value={0}>Select Show</option>
                                        {showRound.map((showList, index) => (
                                            <option value={showList.id} key={index}>{showList.show_name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2 mb-2" style={{display: roundAreaCss}}>
                                    <label htmlFor="inputEmail3" className="col-form-label">Select Round/Area</label>
                                    <select
                                        name={'area_round_id'}
                                        value={input.area_round_id}
                                        onChange={handleInput}
                                        className="form-select form-select-sm"
                                    >
                                        <option value={0}>Select Area/Round</option>
                                        {round_areas.map((round_area, index) => (
                                            <option value={round_area.id} key={index}>{round_area.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2 mb-2" style={{display: showFrSr}}>
                                    <label htmlFor="inputEmail3" className="col-form-label">Select FR/SR</label>
                                    <select
                                        name={'frsr_id'}
                                        value={input.frsr_id}
                                        onChange={handleInput}
                                        className="form-select form-select-sm"
                                    >
                                        <option value={0}>Select FR/SR</option>
                                        {frSr.map((frSrData, index) => (
                                            <option value={frSrData.id} key={index}>{frSrData.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-2 mb-2 custom-padding-top">
                                    <button onClick={getNumbers} className="btn btn-success btn-sm" style={{display: searchBtn}}>Search</button>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="number-container-rate-page">
                                    {Colors && Colors.length ?
                                        Colors.map((colors, i) => (
                                            <>
                                            <div key={i}
                                                    className={`number-item number-item-${colors.color}`}
                                                    style={{color: colors.color == 'Yellow' ? 'black' : 'white'}}>
                                                <h3 className="number">{colors.color}</h3>
                                                <div className="d-flex flex-column">
                                                    <small>Bet: {colors.bet_amount} ({colors.order_count})</small>
                                                    <small>Rtn: {colors.return_amount}</small>
                                                </div>
                                            </div>
                                            </>
                                        )) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="number-container-rate-page align-items-center">
                                            <>
                                                    <>
                                                    {House && House.length ? 
                                                            <div className="house-ending-header">
                                                                <h4>House</h4>
                                                            </div>: null }

                                                            {House && House.length ? 
                                                            House.map((house, i) => (
                                                        <div key={i}house
                                                             className={`number-item number-item-${house.number}`}>
                                                            <h3 className="number">{house.number}</h3>
                                                            <div className="d-flex flex-column">
                                                                <small>Bet: {house.bet_amount} ({house.order_count})</small>
                                                                <small>Rtn: {house.return_amount}</small>
                                                            </div>

                                                        </div>
                                                        )) : null}
                                                    </>
                                            </>

                                    </div>
                                </div>
                            </div>
                            <div className="row mt-4 align-items-center">
                                <div className="col-md-12">
                                    <div className="number-container-rate-page align-items-center">
                                            <>
                                                    <>
                                                    
                                                    {Ending && Ending.length ?
                                                        <div className="house-ending-header">
                                                                <h4>Ending</h4>
                                                            </div>: null}
                                                            
                                                        {Ending && Ending.length ?
                                                            Ending.map((ending, i) => (
                                                        <div key={i}
                                                             className={`number-item number-item-${ending.number}`}>
                                                            <h3 className="number">{ending.number}</h3>
                                                            <div className="d-flex flex-column">
                                                                <small>Bet: {ending.bet_amount} ({ending.order_count})</small>
                                                                <small>Rtn: {ending.return_amount}</small>
                                                            </div>
                                                        </div>
                                                        )) : null}
                                                    </>
                                            </>
                                    </div>
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="number-container-rate-page">
                                    {Numbers && Numbers.length ?
                                        Numbers.map((number, i) => (
                                            <>
                                                    <div className={'number-item'} key={i}>
                                                        <h2 className="number">{number.number}</h2>
                                                        <div className="d-flex flex-column">
                                                            <small>Bet: {number.bet_amount} ({number.order_count})</small>
                                                            <small>Rtn: {number.return_amount}</small>
                                                        </div>
                                                    </div>
                                            </>
                                        )) : null}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        </div>
    );
};

export default BetReturnAmount;
