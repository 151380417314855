import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";
import loader from "../../Assets/Images/loader/ripple.svg";

const PaymentMethodList = () => {
    const [isLoading, setIsLoading]=useState(false)
    const [payment_methods, setPaysmentMethods]= useState([])
    const getPaymentMethodData = () => {
        setIsLoading(true)
      axios.get('/api/dashboard/get-payment-method-number').then(res=>{
          setPaysmentMethods(res.data.data)
          setIsLoading(false)
      })
    }

    useEffect(()=>{
        getPaymentMethodData()
    }, [])
    return (
            <>
                <Helmet>
                    <title>Payment Method List | Teer-125</title>
                </Helmet>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="top-selling-table-section">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>Payment Method List</h5>
                            </div>
                            <div className="row mt-4 position-relative">
                                {isLoading ?
                                    <div className={'table-data-loader'}>
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:null
                                }
                                <div className="col-md-12 table-responsive">
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Account No</th>
                                            <th className="text-center">Country</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Order By</th>
                                            <th className="text-center">Update Time</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {payment_methods.map((method, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{method.name}</td>
                                                <td className="text-center">{method.account_no}</td>
                                                <td className="text-center">{method.country}</td>
                                                <td className="text-center">{method.status}</td>
                                                <td className="text-center">{method.order_by}</td>
                                                <td className="text-center">{method.updated_at}</td>
                                                <td className="text-center">
                                                  <Link to={`/edit-payment-method/${method.id}`}> <button type={'button'} className={'edit-button'}>Edit</button></Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default PaymentMethodList;
