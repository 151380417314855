import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import Pagination from "react-js-pagination";
import axios from "axios";
import $ from "jquery";
import {Modal} from "react-bootstrap";

const ShowList = () => {
    const [showRound, setShowRound] = useState([])
    const [input, setInput]=useState({status:1})
    const [showAddEditTitle, setAddEditTitle] = useState('Add New Show')
    const [mode, setMode] = useState('add')
    const [errors , setErrors] = useState([])
    const [show_id_for_edit, set_show_id_for_edit] = useState(null)
    const [showNew, setShowNew] = useState(false);
    const handleCloseNew = () => setShowNew(false);
    const [isLoading, setIsLoading] = useState()
    // const [isUpdateLoading, setIsUpdateLoading] = useState(false)


    const showList = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
            setIsLoading(false)
        })

    }
    useEffect(() => {
        showList()
    }, [])

    const handleInputChange = (e) => {
        setInput(prevState => ({...prevState,[e.target.name]:e.target.value}))
        console.log(input)
    }
    const handleShowEditList = (id) =>{
        setShowNew(true)
        setInput({})
        setAddEditTitle('Edit Show')
        setMode('edit')
        set_show_id_for_edit(id)
        axios.get('/api/dashboard/shows/'+id).then(res => {
            setInput(res.data)
        })
    };

    const submitData = () => {
        axios.post('/api/dashboard/shows',input).then(res => {
            showList()
            setShowNew(false)
            setInput({})
        }).catch(errors =>{
            if (errors.response.status == 422){
                setErrors(errors.response.data.errors)
            }
        })
    }
    const updateData = () => {
        axios.put('/api/dashboard/shows/'+show_id_for_edit, input).then(res => {
            showList()
            setShowNew(false)
            setInput({})
        })
    }

    const handleShowNew = () => {
        setInput({status: 1})
        setShowNew(true)
        setAddEditTitle('Add New Show')
        setMode('add')
    };


    return (
        <>
            <Helmet>
                <title>Show List List | Teer-125</title>
            </Helmet>
            <div className="row mt-4 position-relative">
                {isLoading ?
                    <div className={'table-data-loader'}>
                        <img src={loader} alt={'Loader'}/>
                    </div> : null
                }
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Show List</h5>
                        </div>

                        <div className="show-list"  id={'show_list'}>
                            <div className="row justify-content-center mt-4">
                                <div className="col-md-12 table-responsive">
                                    {/*table-width-custom*/}
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th className="text-center">Show Name</th>
                                            <th className="text-center">Order By</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {showRound.map((showName, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{showName.show_name}</td>
                                                <td className="text-center">{showName.order_by}</td>
                                                <td className="text-center">{showName.status}</td>
                                                <td onClick={()=>handleShowEditList(showName.id)} className="text-center"><button className={'btn btn-success'}>Edit</button></td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="add-btn-new text-center">
                                        <button onClick={handleShowNew} type={"button"} className={'btn-outline-danger custom-add-btn'}>+Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/*Add List Modal Start*/}
            <div className="add-list-modal">
                <Modal show={showNew} onHide={handleCloseNew}>
                    <Modal.Header closeButton>
                        <Modal.Title>{showAddEditTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="add-show">
                            <div className="row justify-content-center">
                                <div className="col-md-8">
                                    <form>
                                        <div className="mb-3">
                                            <label  className="form-label">Show Name
                                                <input
                                                    type="text"
                                                    name={'show_name'}
                                                    value={input.show_name}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                                {errors.show_name != undefined ?
                                                    <p className={'text-danger'}><small>{errors.show_name[0]}</small></p>:null
                                                }
                                            </label>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Order By
                                                <input
                                                    type="number"
                                                    name={'order_by'}
                                                    value={input.order_by}
                                                    onChange={handleInputChange}
                                                    className="form-control"
                                                />
                                            </label>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">Status
                                                <select
                                                    name={'status'}
                                                    value={input.status}
                                                    onChange={handleInputChange}
                                                    className="form-select"
                                                >
                                                    <option value="1">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </label>
                                        </div>
                                        <button onClick={mode =='add' ?  submitData : updateData} type="button" className="btn btn-primary">{mode == 'add' ? 'Add New Show' : 'Update Show' }</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            {/*Add List Modal End*/}
        </>

    );
};

export default ShowList;
