import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleUp} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";


const HeaderContent = (props) => {
    const [order, setOrder] = useState(props.order)
    return (
        <div className="row">
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Users</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_users}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section tow-order-color h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total User's Balance</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.user_balance}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section tow-order-color h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Bet Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_bet_amount}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Win Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_win_amount}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Orders</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_orders}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section four-order-color h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Running Bet Amount (Orders)</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.running_bet_amount} {order.running_orders != null ? '('+order.running_orders+')' : null}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Today Bet Amount (Orders)</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.today_bet_amount} {order.today_orders != null ? '('+order.today_orders+')' : null}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section tow-order-color h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Today Win Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.today_win_amount}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Deposit Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_deposit_amount}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Weekly Deposit Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.weekly_deposit_amount}</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Total Withdrawal Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.total_withdrawal_amount }</p>
                    </div>
                </div>
            </div>
            <div className="col-md-3 mb-4">
                <div className="order-section h-100">
                    <div className="order-inner">
                        <div className="order-details">
                            <p className={'order'}>Weekly Withdrawal Amount</p>
                            <FontAwesomeIcon icon={faArrowAltCircleUp} className={'icon-color-and-bg'}/>
                        </div>
                        <p className={'order new-order'}>{order.weekly_withdrawal_amount}</p>
                    </div>
                </div>
            </div>
        </div>


    );
};

export default HeaderContent;
