import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import {Link} from "react-router-dom";
import axios from "axios";

const Category = () => {

    const [isLoading, setIsLoading]=useState(false)
    const [category, setCategory]= useState([])

    const getCategoryData = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/all-categories').then(res=>{
            setCategory(res.data.data)
            setIsLoading(false)
        })
    }

    useEffect(()=>{
        getCategoryData()
    }, [])
    return (
        <div>
            <>
                <Helmet>
                    <title>Category List | Teer-125</title>
                </Helmet>
                <div className="row mt-4">
                    <div className="col-md-12">
                        <div className="top-selling-table-section">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>Category List</h5>
                            </div>
                            <div className="row mt-4 position-relative">
                                {isLoading ?
                                    <div className={'table-data-loader'}>
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:null
                                }
                                <div className="col-md-12 table-responsive">
                                    <table className="table table-hover table-striped table-bordered">
                                        <thead>
                                        <tr>
                                            <th className="text-center">SL</th>
                                            <th className="text-center">Name</th>
                                            <th className="text-center">Slug</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Order By</th>
                                            <th className="text-center">Update Time</th>
                                            <th className="text-center">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {category.map((categorys, index) => (
                                            <tr key={index}>
                                                <td className="text-center">{index + 1}</td>
                                                <td className="text-center">{categorys.name}</td>
                                                <td className="text-center">{categorys.slug}</td>
                                                <td className="text-center">{categorys.status}</td>
                                                <td className="text-center">{categorys.order_by}</td>
                                                <td className="text-center">{categorys.updated_at}</td>
                                                <td className="text-center">
                                                    <Link to={`/edit-category/${categorys.id}`}> <button type={'button'} className={'edit-button'}>Edit</button></Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default Category;
