import React from 'react';
import {faBell, faCommentDots, faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import user from '../../Assets/Images/2.jpg'
import $ from 'jquery'
import Swal from "sweetalert2";
import axios from "axios";
import {Link} from "react-router-dom";


const TopBar = () => {

    const handleUserLoginArea = () => {
      $('#admin_logout_area').toggle()
    }

    const logout = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You Will Be Logged Out",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Logout'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/admin-logout').then(res=>{
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_email')
                    localStorage.removeItem('photo_thumb')
                    window.location.reload();
                })
            }
        })
    }
    const handleShowMenu = () => {
    $('#mobile_menu_show').slideToggle();
    }
    return (
        <div className="top-bar-section" id={'top_bar_section'}>
            <div className="top-search-section">
                <div className="mobile-menu" onClick={handleShowMenu}><i className="fas fa-bars bar-icon"/> </div>
                {/* <div className="top-search-and-icon">
                    <form>
                        <input type="text" className={'input-search-box-top-bar'} placeholder={'Search'}/>
                        <FontAwesomeIcon icon={faSearch} className={'top-bar-search-icon'}/>
                    </form>
                </div> */}
                <div className="user-notification-and-msg">
                    <div className="notification-icon-and-msg-icon">
                        {/*<FontAwesomeIcon icon={faCommentDots} className={'massage-icon'}/>*/}
                        {/* <FontAwesomeIcon icon={faBell} className={'massage-icon'}/> */}
                    </div>
                    <div className="user-and-name position-relative" onClick={handleUserLoginArea}>
                        <p className={'admin-name'}>{localStorage.admin_name != undefined?localStorage.admin_name:null}</p>
                        <img src={localStorage.photo_thumb != undefined && localStorage.photo_thumb != null?localStorage.photo_thumb :user} className={'user-img'} alt=""/>


                        <div className="drop-menu-user position-absolute text-center pt-2" id={'admin_logout_area'}>
                            <div className="account_menu"><Link to="/my-account">My Account</Link></div>
                            <button onClick={logout} className={'logout-button'}>Logout</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopBar;
