import React, {useEffect, useState} from 'react';
import HeaderContent from "../Include/HeaderContent";
import TopSelling from "../Include/TopSelling";
import RecentOrder from "../Include/RecentOrder";
import ChartDashboard from "../Include/ChartDashboard";
import {Helmet} from "react-helmet";
import axios from "axios";

const Home = () => {
    const [order, setOrder] = useState([])
    const getDashboardData = () => {
        axios.get('/api/dashboard/dashboard').then(res => {
            setOrder(res.data)
        })
    }
    useEffect(() => {
        getDashboardData()
    }, [])
    return (
      <>
          <Helmet>
              <title>Dashboard | Teer 125</title>
          </Helmet>
          {Object.keys(order).length > 0 ?
              <>
                  <HeaderContent order={order}/>
                  <ChartDashboard  order={order}/>
              </>:null
          }

          <RecentOrder/>
          {/*<TopSelling/>*/}
      </>
    );
};

export default Home;
