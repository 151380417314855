import React, {useEffect, useState} from 'react';
import Chart, {Bar, Doughnut} from "react-chartjs-2";
import $ from 'jquery'
import axios from "axios";
import loader from './../../Assets/Images/loader/pulse.svg'

const ChartDashboard = (props) => {
    const data2 = {
        labels: [
            'Weekly Bet Amount',
            'Weekly Win Amount'
        ],
        datasets: [{
            label: 'Pie Chart',
            data: [props.order.weekly_bet_amount, props.order.weekly_win_amount],
            backgroundColor: [
                'rgb(255, 99, 132)',
                'rgb(54, 162, 235)'
            ],
            hoverOffset: 4
        }]
    }
    const [chart_data, setChartData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const getChartData = (id = 1) => {
        setIsLoading(true)
        axios.get(`/api/dashboard/chart/${id}`).then(res => {
            setChartData(res.data)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getChartData()
    }, []);

    return (
        <div className="row  custom-margin-top">
            <div className="col-md-8">
                <div className="bar-chart-section h-100">
                    <div className="bar-chart-title">
                        <h5 className={'chart-name'}>Business Overview</h5>
                    </div>
                    <div className={'dashboard-chart-data'}>
                        {isLoading ?
                            <img src={loader} alt={'Loader'} />:null
                        }
                        {Object.keys(chart_data).length > 0 ?
                            <div  style={{backgroundColor: isLoading?'silver' : 'transparent', filter: isLoading?'blur(3px)':null }}>
                                <Bar data={chart_data} type={"bar"}/>
                            </div> : null
                        }
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="bar-chart-section h-100">
                    <div className="pie-bar-chart-title">
                        <h5 className={'chart-name text-center'}>Weekly Order Summary</h5>
                    </div>
                    {Object.keys(props.order).length > 0 ?
                        <>
                            <Doughnut data={data2} type={"doughnut"}/>
                            <div className="pie-data-amount">
                                <div style={{backgroundColor: 'rgb(255, 99, 132)'}}>{props.order.weekly_bet_amount}</div>
                                <div style={{backgroundColor: 'rgb(54, 162, 235)'}}>{props.order.weekly_win_amount}</div>
                            </div>
                        </>:null
                    }

                </div>

            </div>
        </div>
    );
};

export default ChartDashboard;
