import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import {Link} from "react-router-dom";
import loader from "../../Assets/Images/loader/ripple.svg";
import {Button, Modal} from "react-bootstrap";
import Pagination from "react-js-pagination";


// import Countdown from "react-countdown";


const User = () => {

    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [slFrom, setSlFrom] = useState(1)
    const [users, setUsers] = useState([])
    const [userSearch, setUserSearch] = useState([])
    const [isLoading, setIsLoading] = useState()

    const getUserList = (page_number = 1) => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-user-list/?page=' + page_number).then(res => {
            setUsers(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }
    
    const getSingleUser = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-user-list/' + userSearch).then(res => {
            setUsers(res.data.data)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getUserList()
    }, []);

    const UserSearchInput = (e, user) => {
        // console.log(e.target.value)
        setUserSearch(e.target.value)
      }


    // Add New User Modal Start
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [errors, setErrors] = useState([])
    const [addUser, setaddUser] = useState({})
    const handleInputChange = (e) => {
        setaddUser(prevState => ({...prevState, [e.target.name]: e.target.value}))
        console.log(addUser)
    }
    const [isUpdateLoading , setIsUpdateLoading] = useState(false)

    const userSubmitData = () => {
        setIsUpdateLoading(true)
        axios.post('/api/user-register',addUser).then(res => {
            setaddUser({})
            setShow(false)
             getUserList()
            setIsUpdateLoading(false)
        }).catch(errors =>{
            if (errors.response.status == 422){
                setErrors(errors.response.data.errors)
                 setIsUpdateLoading(false)
            }
        })
    }
    // Add New User Modal End

    return (
        <>
            <Helmet>
                <title>User List | Teer-125</title>
            </Helmet>
            <div className="row mt-4 position-relative">
                {isLoading ?
                    <div className={'table-data-loader'}>
                        <img src={loader} alt={'Loader'}/>
                    </div> : null
                }
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>User List</h5>
                            <button class={'add-new-btn-game-time custom-position'} onClick={handleShow}>Add New User</button>
                        </div>
                        <div className="row mt-4">
                        <div className="user-list-search-box">
                                <input onChange={(e)=>UserSearchInput(e, 'user')} className="user-list-search-box-main" type="text" placeholder="Email / Phone / Name / ID"/>
                                <button onClick={getSingleUser} className="btn btn-success btn-sm">Search</button>
                            </div>
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-hover table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th className="text-center align-middle">SL</th>
                                                <th className="text-center align-middle">Name</th>
                                                <th className="text-center align-middle">Email</th>
                                                <th className="text-center align-middle">Phone</th>
                                                <th className="text-center align-middle">Username</th>
                                                <th className="text-center align-middle">Account ID</th>
                                                <th className="text-center align-middle">Balance</th>
                                                <th className="text-center align-middle">Country</th>
                                                <th className="text-center align-middle">Registered At</th>
                                                <th className="text-center align-middle">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {users.map((user, index)=>(
                                            <tr key={index}>
                                                <td className="text-center align-middle">{slFrom+index}</td>
                                                <td className="text-center align-middle">{user.name}</td>
                                                <td className="text-center align-middle">{user.email}</td>
                                                <td className="text-center align-middle">{user.phone}</td>
                                                <td className="text-center align-middle">{user.username}</td>
                                                <td className="text-center align-middle">{user.account_id}</td>
                                                <td className="text-center align-middle">{user.balance}</td>
                                                <td className="text-center align-middle">{user.country}</td>
                                                <td className="text-center align-middle">{user.created_at}</td>
                                                <td className="text-center align-middle">
                                                    <Link to={`user-details/${user.id}`}><button className={'btn btn-sm btn-info text-white'}><i className={'fas fa-eye'}/></button></Link>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    {totalItemsCount > 20?
                                        <nav className={'pagination justify-content-end'}>
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                // pageRangeDisplayed={5}
                                                onChange={getUserList}
                                                nextPageText="Next"
                                                prevPageText="Previous"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                itemClassNext="page-item"
                                                itemClassPrev="page-item"
                                                itemClassFirst="page-item"
                                                itemClassLast="page-item"
                                                firstPageText="First"
                                                lastPageText="Last"
                                            />
                                        </nav> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Add User Modal start*/}
                <>
                    <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add New User</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="add-new-user-form">
                                <form>
                                    <div className="row justify-content-center">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Name
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name={'name'}
                                                                value={addUser.name}
                                                                onChange={handleInputChange}
                                                            />
                                                        </label>
                                                        {errors.name != undefined ?
                                                            <p className={'text-danger'}><small>{errors.name[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Email
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                name={'email'}
                                                                value={addUser.email}
                                                                onChange={handleInputChange}
                                                            />
                                                        </label>
                                                        {errors.email != undefined ?
                                                            <p className={'text-danger'}><small>{errors.email[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Pasword
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                name={'password'}
                                                                value={addUser.password}
                                                                onChange={handleInputChange}
                                                            />
                                                        </label>
                                                        {errors.password != undefined ?
                                                            <p className={'text-danger'}><small>{errors.password[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Phone
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name={'phone'}
                                                                value={addUser.phone}
                                                                onChange={handleInputChange}
                                                            />
                                                        </label>
                                                        {errors.phone != undefined ?
                                                            <p className={'text-danger'}><small>{errors.phone[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">User Name
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name={'username'}
                                                                value={addUser.username}
                                                                onChange={handleInputChange}
                                                            />
                                                        </label>
                                                        {errors.username != undefined ?
                                                            <p className={'text-danger'}><small>{errors.username[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-2">
                                                        <label className="form-label">Country
                                                            <select
                                                                className="form-select"
                                                                name={'country_id'}
                                                                value={addUser.country_id}
                                                                onChange={handleInputChange}
                                                            >
                                                                <option  selected >Select Your Country</option>
                                                                <option  value={'1'}>Bangladesh</option>
                                                                <option value={'2'}>India</option>
                                                                <option value={'3'}>Bhutan</option>
                                                                <option value={'4'}>Nepal</option>
                                                                <option value={'5'}>Singapore</option>
                                                            </select>
                                                        </label>
                                                        {errors.country_id != undefined ?
                                                            <p className={'text-danger'}><small>{errors.country_id[0]}</small></p>:null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="new-user-btn text-center">
                                        <button onClick={userSubmitData} className={'add-new-btn-game-time custom-position'} type={"button"}dangerouslySetInnerHTML={{__html: isUpdateLoading ? '<div class="spinner-border spinner-border-sm"></div>' : 'Add New User'}}/>
                                    </div>

                                </form>
                            </div>
                        </Modal.Body>
                    </Modal>
                </>
                {/*Add User Modal End*/}

            </div>

        </>
    );
};

export default User;
