import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import axios from "axios";
import loader from "../../Assets/Images/loader/ripple.svg";
import {Modal} from "react-bootstrap";
// import Parse from 'parse/dist/parse.min.js';

const AdminList = () => {

    const [adminList, setAdminList] = useState([])
    const [isLoading, setIsLoading] = useState()

    const getAdminList = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/admin-list').then(res => {
            setAdminList(res.data.data)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getAdminList()
    }, []);


    const type = localStorage.admin_type

    // console.log(type);
    // const token = localStorage.admin_token
    // console.log(token)

    // Add New User Modal Start
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [buttonText, setButtonText] = useState('Add New Admin')
    const handleShow = (e, id = null) => {
        setErrors([])
        if (id != null) {
            console.log('if')
            setButtonText('Update Admin')
            axios.get('/api/dashboard/show-admin/' + id).then(res => {
                setAddAdmin(res.data)
            })
        }else{
            setButtonText('Add New Admin')
            setAddAdmin({})
        }
        setShow(true);
    }


    const [errors, setErrors] = useState([])
    const [addAdmin, setAddAdmin] = useState({})
    const handleInputChange = (e) => {
        setAddAdmin(prevState => ({...prevState, [e.target.name]: e.target.value}))
        // console.log(addAdmin)
    }
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)

    const AdminDataSubmit = () => {
        setIsUpdateLoading(true)
        if (buttonText == 'Update Admin'){
            axios.put('/api/dashboard/update-admin/'+addAdmin.id, addAdmin).then(res => {
                setAddAdmin({})
                setShow(false)
                getAdminList()
                setIsUpdateLoading(false)
                setErrors([])
            }).catch(errors => {
                if (errors.response.status == 422) {
                    setErrors(errors.response.data.errors)
                    setIsUpdateLoading(false)
                }
            })
        }else{
            axios.post('/api/dashboard/create-admin', addAdmin).then(res => {
                setAddAdmin({})
                setShow(false)
                getAdminList()
                setIsUpdateLoading(false)
                setErrors([])
            }).catch(errors => {
                if (errors.response.status == 422) {
                    setErrors(errors.response.data.errors)
                    setIsUpdateLoading(false)
                }
            })
        }

    }

    const handleDelete = (id) => {
        axios.delete('/api/dashboard/delete-admin/' + id).then(res => {
            setAddAdmin(res.data)
            getAdminList()
        })
    }
    const adminType = localStorage.getItem("admin_type");


    // Add New User Modal End

    return (
        <div>
            <>
                <Helmet>
                    <title>Admin List | Teer-125</title>
                </Helmet>
                <div className="row mt-4 position-relative">
                    {isLoading ?
                        <div className={'table-data-loader'}>
                            <img src={loader} alt={'Loader'}/>
                        </div> : null
                    }
                    <div className="col-md-12">
                        <div className="top-selling-table-section">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>Admin List</h5>

                                {adminType == 1 ?
                                    <>
                                        <button className={'add-new-btn-game-time custom-position'}
                                                onClick={handleShow}>Add New Admin
                                        </button>
                                    </> : null
                                }

                            </div>
                            <div className="row mt-4">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-hover table-bordered table-striped">
                                            <thead>
                                            <tr>
                                                <th className="text-center align-middle">SL</th>
                                                <th className="text-center align-middle">Name</th>
                                                <th className="text-center align-middle">Email</th>
                                                <th className="text-center align-middle">Phone</th>
                                                <th className="text-center align-middle">Username</th>
                                                <th className="text-center align-middle">Account ID</th>
                                                <th className="text-center align-middle">Country</th>
                                                <th className="text-center align-middle">Registered At</th>
                                                {adminType == 1 ?
                                                    <th className="text-center align-middle">Status</th> : null
                                                }

                                                {/*<th className="text-center align-middle">Action</th>*/}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {adminList.map((user, index) => (
                                                <tr key={index}>
                                                    <td className="text-center align-middle">{index + 1}</td>
                                                    <td className="text-center align-middle">{user.name}</td>
                                                    <td className="text-center align-middle">{user.email}</td>
                                                    <td className="text-center align-middle">{user.phone}</td>
                                                    <td className="text-center align-middle">{user.username}</td>
                                                    <td className="text-center align-middle">{user.account_no}</td>
                                                    <td className="text-center align-middle">{user.country}</td>
                                                    <td className="text-center align-middle">{user.created_at}</td>

                                                    {adminType == 1 ?
                                                        <td className="text-center align-middle">
                                                            <>
                                                                <button className={'btn btn-success btn-sm m-1'}
                                                                        onClick={(e) => handleShow(e, user.id)}> Edit
                                                                </button>
                                                                <button className={'btn btn-danger btn-sm m-1'} onClick={()=>handleDelete(user.id)}>Delete
                                                                </button>
                                                            </>
                                                        </td> : null
                                                    }
                                                    {/*<td className="text-center align-middle">*/}
                                                    {/*    <Link to={`user-details/${user.id}`}><button className={'btn btn-sm btn-info text-white'}><i className={'fas fa-eye'}/></button></Link>*/}
                                                    {/*</td>*/}
                                                </tr>
                                            ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*Add User Modal start*/}
                    <>
                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>{buttonText}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="add-new-user-form">
                                    <form>
                                        <div className="row justify-content-center">
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Name
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={'name'}
                                                                    value={addAdmin.name}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </label>
                                                            {errors.name != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.name[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Email
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    name={'email'}
                                                                    value={addAdmin.email}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </label>
                                                            {errors.email != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.email[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    {buttonText != 'Update Admin' ? <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Password
                                                                <input
                                                                    type="password"
                                                                    className="form-control"
                                                                    name={'password'}
                                                                    value={addAdmin.password}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </label>
                                                            {errors.password != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.password[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>:null }


                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Phone
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={'phone'}
                                                                    value={addAdmin.phone}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </label>
                                                            {errors.phone != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.phone[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">User Name
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name={'username'}
                                                                    value={addAdmin.username}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </label>
                                                            {errors.username != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.username[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Type
                                                                <select
                                                                    className="form-select"
                                                                    name={'type'}
                                                                    value={addAdmin.type}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option selected>Select Admin Type</option>
                                                                    <option value={'1'}>Super Admin</option>
                                                                    <option value={'2'}>Admin</option>

                                                                </select>
                                                            </label>
                                                            {errors.type != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.type[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="mb-2">
                                                            <label className="form-label">Country
                                                                <select
                                                                    className="form-select"
                                                                    name={'country_id'}
                                                                    value={addAdmin.country_id}
                                                                    onChange={handleInputChange}
                                                                >
                                                                    <option selected>Select Your Country</option>
                                                                    <option value={'1'}>Bangladesh</option>
                                                                    <option value={'2'}>India</option>
                                                                    <option value={'3'}>Bhutan</option>
                                                                    <option value={'4'}>Nepal</option>
                                                                    <option value={'5'}>Singapore</option>
                                                                </select>
                                                            </label>
                                                            {errors.country_id != undefined ?
                                                                <p className={'text-danger'}>
                                                                    <small>{errors.country_id[0]}</small></p> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="new-user-btn text-center">
                                            <button onClick={AdminDataSubmit}
                                                    className={'add-new-btn-game-time custom-position'} type={"button"}
                                                    dangerouslySetInnerHTML={{__html: isUpdateLoading ? '<div class="spinner-border spinner-border-sm"></div>' : buttonText}}/>
                                        </div>

                                    </form>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </>
                    {/*Add User Modal End*/}


                </div>

            </>
        </div>
    );
};

export default AdminList;
