import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import {Link, useParams} from "react-router-dom";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import loader from "../../Assets/Images/loader/ripple.svg";
import $ from "jquery";
import {Modal} from "react-bootstrap";

// import Countdown from "react-countdown";


const UserData = () => {

    const [user, setUser] = useState([])
    const prams = useParams()

    const getUserList = () => {
        axios.get('/api/dashboard/get-user-details/' + prams.id).then(res => {
            setUser(res.data.data)
        })
    }

    const loginAsUser = () => {
        axios.post('/api/dashboard/login-as-user/' + user.id).then(res => {
            window.open(`https://teer125.com/?u=${res.data.data.token}&e=${res.data.data.email}&n=${res.data.data.name} (Login as User)`, "_blank");
        })
    }
    

    useEffect(() => {
        getUserList()
    }, []);




    const [isLoading, setIsLoading] = useState(false)



    const [transactions, setTransactions] = useState([])
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)

    const getTransaction = (page_number = 1) => {
        setIsLoading(true)
        axios.get(`/api/dashboard/user-transections/${prams.id}?page=${page_number}&status=${transaction_status}&type=${transaction_type}`).then(res => {
            setTransactions(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }


    useEffect(() => {
        getTransaction()
    }, []);

    const [approveModalShow, setApproveModalShow] = useState(false)
    const [transactionInput, setTransactionInput] = useState({})
    const [transaction_id, set_transaction_id] = useState({})

    const handleApproveModal = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        if (approveModalShow) {
            setApproveModalShow(false)
        } else {
            if (id != null) {
                setTransactionInput(prevState => ({...prevState, transaction_id: transaction_id, amount: amount, payment_method_id: payment_method_id, account_no: account_no}))
                set_transaction_id(id)
            }
            setApproveModalShow(true)
        }
    }

    const [withdrawApproveModalShow, setWithdrawApproveModalShow] = useState(false)
    const [withdrawTransactionInput, setWithdrawTransactionInput] = useState({})

    const handleWithdrawApproveModal = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {


        if (withdrawApproveModalShow) {
            setWithdrawApproveModalShow(false)
        } else {
            if (id != null) {
                setWithdrawTransactionInput(prevState => ({...prevState, transaction_id: transaction_id, amount: amount, payment_method_id: payment_method_id, account_no: account_no}))
                set_transaction_id(id)
            }
            setWithdrawApproveModalShow(true)
        }
    }

    const handleWithdrawDisApprove = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "Approval Request will be canceled",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel-approve-withdraw-request/' + id).then(res => {
                    if (res.data.status == 'fail') {
                        setError(res.data.msg)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        getTransaction()
                    }
                })
            }
        })
    }
    const handleCancelApprove = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "Approval Request will be canceled",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel-approve-request/' + id).then(res => {
                    if (res.data.status == 'fail') {
                        setError(res.data.msg)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        getTransaction()
                    }
                })
            }
        })
    }

    const handleWithdrawTransactionInput = (e) => {
        setWithdrawTransactionInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const handleTransactionInput = (e) => {
        setTransactionInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const [error, setError] = useState(null)

    const handleWithdrawTransactionApprove = () => {
        setError(null)
        console.log(withdrawTransactionInput)
        axios.post('/api/dashboard/approve-withdraw-request/' + transaction_id, withdrawTransactionInput).then(res => {
            if (res.data.status == 'fail') {
                setError(res.data.msg)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                handleWithdrawApproveModal()
                getTransaction()
            }
        })
    }

    const handleTransactionApprove = () => {
        setError(null)
        axios.post('/api/dashboard/approve-transaction/' + transaction_id, transactionInput).then(res => {
            if (res.data.status == 'fail') {
                setError(res.data.msg)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                handleApproveModal()
                getTransaction()
            }
        })
    }


    const [payment_methods, set_payment_methods] = useState([])

    const getPaymentMethods = () => {
        axios.get('/api/dashboard/get-payment-methods').then(res => {
            set_payment_methods(res.data)
        })
    }

    useEffect(() => {
        getPaymentMethods()
    }, [])

    const [orders, setOrders] = useState([])
    // const getOrders = (page_number = 1) => {
    //     setIsLoading(true)
    //     axios.get('/api/dashboard/user-orders/'+prams.id+'?page=' + page_number).then(res => {
    //         setOrders(res.data.data)
    //         setIsLoading(false)
    //     })
    // }

    useEffect(() => {
        getOrders()
        getCategories()
    }, []);

    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')

    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const [round_areas, set_round_areas] = useState([])
    const getRoundArea = (id) => {
        axios.get('/api/dashboard/get-round-area/' + id).then(res => {
            set_round_areas(res.data)
        })
    }

    const [transaction_type, setTransaction_type] = useState(0)
    const handleTransactionType = (e, status) => {
        $(`#type_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransaction_type(status)
    }

    const [transaction_status, setTransactionStatus] = useState(0)
    const handleTransactionStatus = (e, status) => {
        $(`#status_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransactionStatus(status)
    }
    const [slFrom, setSlFrom] = useState(1)

    useEffect(()=>{
        getTransaction()
    }, [transaction_status, transaction_type])


    // ---------------------------order List start--------------------------------
    const [roundAreaCss, setRoundAreaCss] = useState('none')
    const [showRoundList, setShowRoundList] = useState('none')
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

        if (e.target.name == 'category_id') {
            if (e.target.value == 1) {
                setShowFrSr('none')
                setRoundAreaCss('none')
                showList()
                setShowRoundList('block')
                setInput(prevState => ({...prevState, round_show_id: 0, area_round_id: 0}))
            } else if (e.target.value == 2) {
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            } else if (e.target.value == 3) {
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            } else if (e.target.value == 0) {
                setShowRoundList('none')
                setRoundAreaCss('none')
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'round_show_id') {
            if (e.target.value != 0) {
                getShowArea(e.target.value)
                setRoundAreaCss('block')
            } else {
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'area_round_id' && input.category_id == 2) {
            if (e.target.value != 0) {
                setShowFrSr('block')
            } else {
                setShowFrSr('none')
            }
        }

    }
    const getShowArea = (id) => {
        axios.get('/api/dashboard/get-round-list/1/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }
    const [input, setInput] = useState({})


    const [orderItemsCountPerPage, setOrderItemsCountPerPage] = useState(20)
    const [orderTotalItemsCount, setOrderTotalItemsCount] = useState(0)
    const [orderActivePage, setOrderActivePage] = useState(1)
    const [orderSlFrom, setOrderSlFrom] = useState(1)

    const getOrders = (page_number = 1) => {
        setIsLoading(true)
        axios.post('/api/dashboard/user-orders/'+prams.id+'?page=' + page_number, input).then(res => {

            setOrders(res.data.data)
            setOrderItemsCountPerPage(parseInt(res.data.meta.per_page))
            setOrderTotalItemsCount(res.data.meta.total)
            setOrderActivePage(res.data.meta.current_page)
            setOrderSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }
    useEffect(() => {
        getOrders()
        getCategories()
        showList()
    }, []);

    

    const handleRefund = (e, id) => {


        Swal.fire({
            title: 'Are you sure to cancel?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel/'+id).then(res => {
                    getOrders()
                    getUserList()
                })
            }
        })


    }


    return (
        <>
            <Helmet>
                <title>User Details | Teer-125</title>
            </Helmet>
            <div className="row mt-4 justify-content-center">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>User Details</h5>
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-6">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4>Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered table-striped">
                                                <tbody>
                                                <tr>
                                                    <th>Account ID</th>
                                                    <th>{user.account_id}</th>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>{user.name}</th>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>{user.email}</th>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <th>{user.phone}</th>
                                                </tr>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>{user.username}</th>
                                                </tr>
                                                <tr>
                                                    <th>Registered at</th>
                                                    <th>{user.created_at}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                            <div className="text-center">
                                            <button onClick={loginAsUser} className="btn btn-sm btn-warning"><i className="fas fa-user me-1"/>  Login as User</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4>Account Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered table-striped">
                                                <tbody>
                                                <tr>
                                                    <th>Balance</th>
                                                    <th>{user.balance}</th>
                                                </tr>
                                                <tr>
                                                    <th>Currency</th>
                                                    <th>{user.country != null ? (user.country == 'Bangladesh'?' BDT':' INR') : null}</th>
                                                </tr>
                                                <tr>
                                                    <th>Country</th>
                                                    <th>{user.country}</th>
                                                </tr>
                                                <tr>
                                                    <th>Payment Method</th>
                                                    <th>
                                                        {user.payment_method != undefined ? user.payment_method.map((payment_method, payInd)=>(
                                                            <p><strong>{payment_method.name}</strong> : {payment_method.account_no}</p>
                                                        )):null}
                                                    </th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>User Transaction Details</h5>

                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-2">
                                <div className="button-transaction text-center">
                                    <button onClick={(e) => handleTransactionType(e, '0')} id={'type_button_0'} className={'transaction-btn transaction-btn-active'} type={'button'}>All</button>
                                    <button onClick={(e) => handleTransactionType(e, '1')} id={'type_button_1'} className={'transaction-btn'} type={'button'}>Bet</button>
                                    <button onClick={(e) => handleTransactionType(e, '2')} id={'type_button_2'} className={'transaction-btn'} type={'button'}>Add Fund</button>
                                    <button onClick={(e) => handleTransactionType(e, '3')} id={'type_button_3'} className={'transaction-btn'} type={'button'}>Withdraw</button>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                                <div className="button-transaction text-center">
                                    <button onClick={(e) => handleTransactionStatus(e, '0')} id={'status_button_0'} className={'transaction-btn transaction-btn-active'} type={'button'}>All</button>
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '1')} id={'status_button_1'} className={'transaction-btn'} type={'button'}>Running</button> : null}
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '2')} id={'status_button_2'} className={'transaction-btn'} type={'button'}>Win</button> : null}
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '3')} id={'status_button_3'} className={'transaction-btn'} type={'button'}>Loss</button> : null}
                                    {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '4')} id={'status_button_4'} className={'transaction-btn'} type={'button'}>Pending</button> : null}
                                    {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '5')} id={'status_button_5'} className={'transaction-btn'} type={'button'}>Approved</button> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-12 table-responsive">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="text-center">SL</th>
                                        <th className="text-center">Time</th>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Method</th>
                                        <th className="text-center">Transaction ID</th>
                                        <th className="text-center">Account No</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td className="align-middle text-center">{slFrom + index}</td>
                                            <td className="align-middle text-center">{transaction.time}</td>
                                            <td className="align-middle text-center">{transaction.type}</td>
                                            <td className="align-middle text-center">

                                                <small>
                                                    <p>{transaction.category}</p>
                                                    <p>{transaction.round} {transaction.frsr != null ? <span class="order-table-frsr-pill">{transaction.frsr}</span> : null}</p>
                                                    <p>{transaction.investment_type} {transaction.number != null ? <span class="order-table-number-pill" style={transaction.number == 'Green' ? {backgroundColor: 'green', color: 'white'} : transaction.number == 'Red' ? {backgroundColor: 'red', color: 'white'} : transaction.number == 'Yellow' ? {backgroundColor: 'yellow', color: 'black'} : null}>{transaction.number}</span> : null}</p>
                                                </small>
                                                {transaction.method}
                                            </td>
                                            <td className="align-middle text-center">{transaction.transaction_id}</td>
                                            <td className="align-middle text-center">{transaction.account_no}</td>
                                            <td className="align-middle text-center">{transaction.amount}</td>
                                            <td className="align-middle text-center">{transaction.status}</td>
                                            <td className="align-middle text-center">
                                                {transaction.type == 'Add Fund' && transaction.status == 'Pending' ?
                                                    <>
                                                        <button
                                                            onClick={() => handleCancelApprove(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                            className="btn btn-sm btn-warning  me-1"><i
                                                            className="fas fa-times"/> Cancel
                                                        </button>
                                                        <button
                                                            onClick={() => handleApproveModal(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                            className="btn btn-sm btn-warning"><i
                                                            className="fas fa-check me-1"/> Approve
                                                        </button>
                                                    </>
                                                    : null
                                                }

                                                {transaction.type == 'Withdraw' && transaction.status == 'Pending' ?
                                                    <>
                                                        <button
                                                            onClick={() => handleWithdrawDisApprove(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                            className="btn btn-sm btn-warning  me-1"><i
                                                            className="fas fa-times"/> Cancel
                                                        </button>
                                                        <button
                                                            onClick={() => handleWithdrawApproveModal(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                            className="btn btn-sm btn-warning"><i
                                                            className="fas fa-check me-1"/> Approve
                                                        </button>
                                                    </>

                                                    : null
                                                }

                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                                {totalItemsCount > 20 ?
                                    <nav className={'pagination justify-content-end'}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={itemsCountPerPage}
                                            totalItemsCount={totalItemsCount}
                                            // pageRangeDisplayed={5}
                                            onChange={getTransaction}
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassNext="page-item"
                                            itemClassPrev="page-item"
                                            itemClassFirst="page-item"
                                            itemClassLast="page-item"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                    </nav> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>User Order List</h5>
                        </div>
                        {/*order list table start*/}
                        <div className="row align-items-end">
                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Category</label>
                                <select
                                    name={'category_id'}
                                    value={input.category_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select Category</option>
                                    {categories.map((category, index) => (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: showRoundList}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select Show</label>
                                <select
                                    name={'round_show_id'}
                                    value={input.round_show_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Show</option>
                                    {showRound.map((showList, index) => (
                                        <option value={showList.id} key={index}>{showList.show_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: roundAreaCss}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select Round/Area</label>
                                <select
                                    name={'area_round_id'}
                                    value={input.area_round_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select Area/Round</option>
                                    {round_areas.map((round_area, index) => (
                                        <option value={round_area.id} key={index}>{round_area.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: showFrSr}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select FR/SR</label>
                                <select
                                    name={'frsr_id'}
                                    value={input.frsr_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select FR/SR</option>
                                    {frSr.map((frSrData, index) => (
                                        <option value={frSrData.id} key={index}>{frSrData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Status</label>
                                <select
                                    name={'status'}
                                    value={input.status}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={null}>Select Status</option>
                                    <option value={1}>Running</option>
                                    <option value={3}>Loss</option>
                                    <option value={2}>Win</option>
                                </select>
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Amount</label>
                                <select
                                    name={'order'}
                                    value={input.order}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={null}>Select Status</option>
                                    <option value={'desc'}>Bigger</option>
                                    <option value={'asc'}>Lower</option>

                                </select>
                            </div>

                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Date</label>
                                <input
                                    type={'date'}
                                    name={'date'}
                                    value={input.date}
                                    onChange={handleInput}
                                    className="form-control form-control-sm"
                                />
                            </div>
                            <div className="col-md-2 custom-padding-top">
                                <button onClick={getOrders} className="btn btn-success btn-sm">Search</button>
                            </div>

                        </div>
                        <div className="row mt-4  position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-12 table-responsive">

                                <table className="table table-hover table-striped table-bordered text-center">
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Time</th>
                                        <th>User</th>
                                        <th>Category</th>
                                        <th>Round/Area</th>
                                        <th>Number/Color</th>
                                        <th>Result Time</th>
                                        <th>Amount</th>
                                        <th>Return</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className={'text-center'}>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{index + orderSlFrom}</td>
                                            <td>{order.time}</td>
                                            <td>{order.user}</td>
                                            <td>{order.category}</td>
                                            <td>
                                                {order.frsr != null ?
                                                    <span className={'order-table-frsr-pill'}>{order.frsr}</span> : null
                                                }
                                                {order.show_id? <span className={'order-table-frsr-pill'}>{order.show_id[0]}</span>
                                                    :null }
                                                {order.round}
                                            </td>
                                            <td>
                                                <div className="order-table-number-flex">
                                                    <div className={'first'}>{order.investment_type} </div>

                                                    <div className={'third'}
                                                         style={order.status_id === 1 ?
                                                             {backgroundColor: '#e35252', color: 'white'}
                                                             : (order.status_id === 2 ? {
                                                                     backgroundColor: 'green',
                                                                     color: 'white'
                                                                 }
                                                                 : (order.status_id === 3 ? {
                                                                         backgroundColor: 'red',
                                                                         color: 'white'
                                                                     }
                                                                     : (order.status_id === 4 ? {
                                                                             backgroundColor: '#e35252',
                                                                             color: 'white'
                                                                         }
                                                                         : (order.status_id === 5 ? {
                                                                                 backgroundColor: 'green',
                                                                                 color: 'white'
                                                                             }
                                                                             : (order.status_id === 6 ? {
                                                                                 backgroundColor: 'red',
                                                                                 color: 'white'
                                                                             } : null)))))}
                                                    > {order.number}

                                                    </div>
                                                </div>
                                            </td>
                                            <td>{order.result_time}</td>
                                            <td
                                                style={order.status_id === 1 ?
                                                    {backgroundColor: '#e35252', color: 'white'}
                                                    : (order.status_id === 3 ? {
                                                        backgroundColor: 'red',
                                                        color: 'white'
                                                    } : null)}
                                            >{order.amount}</td>

                                            <td
                                                style={order.status_id === 2 ?
                                                    {backgroundColor: 'green', color: 'white'} : null}
                                            >{order.return_amount}</td>

                                            <td style={order.status_id === 1 ?
                                                {backgroundColor: '#e35252', color: 'white'}
                                                : (order.status_id === 2 ? {backgroundColor: 'green', color: 'white'}
                                                    : (order.status_id === 3 ? {backgroundColor: 'red', color: 'white'}
                                                        : (order.status_id === 4 ? {
                                                                backgroundColor: '#e35252',
                                                                color: 'white'
                                                            }
                                                            : (order.status_id === 5 ? {
                                                                    backgroundColor: 'green',
                                                                    color: 'white'
                                                                }
                                                                : (order.status_id === 6 ? {
                                                                    backgroundColor: 'red',
                                                                    color: 'white'
                                                                } : null)))))}>
                                                <p> {order.status}</p>
                                                {order.status_id != 6 ?
                                                    <button
                                                        className={'btn btn-info btn-sm'}
                                                        type={'button'}
                                                        onClick={(e)=>handleRefund(e,order.id )}
                                                    >
                                                        Cancel
                                                    </button>:null
                                                }
                                            </td>

                                        </tr>
                                    ))}

                                    </tbody>
                                </table>

                                {orderTotalItemsCount > 20 ?
                                    <nav className={'pagination justify-content-end'}>
                                        <Pagination
                                            activePage={orderActivePage}
                                            itemsCountPerPage={orderItemsCountPerPage}
                                            totalItemsCount={orderTotalItemsCount}
                                            // pageRangeDisplayed={5}
                                            onChange={getOrders}
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassNext="page-item"
                                            itemClassPrev="page-item"
                                            itemClassFirst="page-item"
                                            itemClassLast="page-item"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                    </nav> : null
                                }
                            </div>
                        </div>
                        {/*order list table end*/}
                    </div>
                </div>
            </div>
            {/*-------------------------Aaprove modal Start------------------------*/}
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={approveModalShow} onHide={handleApproveModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Approve Transaction
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        name={'transaction_id'}
                        value={transactionInput.transaction_id}
                        onChange={handleTransactionInput}
                    />
                    {error != null ?
                        <small className="text-danger">{error}</small> : null
                    }
                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'amount'}
                        value={transactionInput.amount}
                        onChange={handleTransactionInput}
                    />


                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'account_no'}
                        value={transactionInput.account_no}
                        onChange={handleTransactionInput}
                        placeholder={'Account Number'}
                    />


                    <select
                        className={'form-select mt-2'}
                        onChange={handleTransactionInput}
                        value={transactionInput.payment_method_id}
                        name={'payment_method_id'}
                        placeholder={'Enter Amount'}
                    >
                        <option value={0}>Select Payment Method</option>
                        {payment_methods.map((method, index) => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </select>
                    <div className="d-grid mt-3">
                        <button onClick={handleTransactionApprove} className="btn btn-success">Approve</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={withdrawApproveModalShow} onHide={handleWithdrawApproveModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Approve Withdraw
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        name={'transaction_id'}
                        value={withdrawTransactionInput.transaction_id}
                        onChange={handleWithdrawTransactionInput}
                    />
                    {error != null ?
                        <small className="text-danger">{error}</small> : null
                    }
                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'amount'}
                        value={withdrawTransactionInput.amount}
                        onChange={handleWithdrawTransactionInput}
                    />


                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'account_no'}
                        value={withdrawTransactionInput.account_no}
                        onChange={handleWithdrawTransactionInput}
                        placeholder={'Account Number'}
                    />

                    <select
                        className={'form-select mt-2'}
                        onChange={handleWithdrawTransactionInput}
                        value={withdrawTransactionInput.payment_method_id}
                        name={'payment_method_id'}
                        placeholder={'Enter Amount'}
                    >
                        <option value={0}>Select Payment Method</option>
                        {payment_methods.map((method, index) => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </select>


                    <div className="d-grid mt-3">
                        <button onClick={handleWithdrawTransactionApprove} className="btn btn-success">Approve</button>
                    </div>
                </Modal.Body>
            </Modal>
            {/*-------------------------Aaprove modal End------------------------*/}
        </>
    );
};

export default UserData;
