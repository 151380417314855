import React, {useEffect, useState} from "react";
import './Assets/style.css';
import './Assets/midea.css';
import 'animate.css';
import {BrowserRouter, Route} from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import ScrollToTop from "react-scroll-to-top";
import Home from "./Components/Pages/Home";
import TopBar from "./Components/Include/TopBar";
import SideNavBar from "./Components/Include/SideNavBar";
import Auth from "./Auth";
import 'react-toastify/dist/ReactToastify.css';
import LoginPage from "./Components/Include/LoginPage";
import ReturnRate from "./Components/Pages/ReturnRate";
import axios from "axios";
import Transaction from "./Components/Pages/Transaction";
import OrderList from "./Components/Pages/OrderList";
import RunningOrder from "./Components/Pages/RunningOrder";
import Result from "./Components/Pages/Result";
import GameTime from "./Components/Pages/GameTime";
import User from "./Components/Pages/User";
import UserData from "./Components/Pages/UserDetails";
import PreviousResult from "./Components/Pages/PreviousResult";
import PaymentMethodList from "./Components/Pages/PaymentMethodList";
import EditPaymentMethodList from "./Components/Pages/EditPaymentMethodList";
import Category from "./Components/Pages/Category";
import EditCategory from "./Components/Pages/EditCategory";
import AreaList from "./Components/Pages/AreaList";
import EditArea from "./Components/Pages/EditArea";
import ShowList from "./Components/Pages/ShowList";
import AdminList from "./Components/Pages/AdminList";
import AddNewUser from "./Components/Pages/AddNewUser";
import FrSrList from "./Components/Pages/FrSrList";
import UserTransactionDetails from "./Components/Pages/UserTransactionDetails";
import UserOrderList from "./Components/Pages/UserOrderList";
import BetReturnAmount from "./Components/Pages/BetReturnAmount";
import MyAccount from "./Components/Pages/MyAccount";

function App() {

    const [url, setUrl] = useState('')
    useEffect(() => {
        setUrl(window.location.pathname)
    }, [])


    useEffect(() => {
        let url = window.location.pathname
        if (url != '/login') {
            axios.post('/api/dashboard/check-admin-auth').then(res => {
                if (res.data.msg == true) {

                } else {
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                }
            }).catch(errors => {
                if (errors.response.status === 401) {
                    localStorage.removeItem('admin_token')
                    localStorage.removeItem('admin_name')
                    localStorage.removeItem('admin_email')
                    window.location.reload()
                }
            })
        }
    }, [])


    return (
        <div className="App">
            <ScrollToTop
                smooth
                top={75}
            />
            {url == '/login' ?
                <BrowserRouter>
                    <Route exact path="/login" component={LoginPage}/>
                </BrowserRouter> :
                <BrowserRouter>
                    <div className="container-fluid g-0 overflow-hidden">
                        <div className="row">
                            <div className="col-md-2">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-10 ps-md-0">
                                <TopBar/>
                                <div className="container mr-top-header-content px-3">
                                    <Auth exact path="/demo-route" component={Home}/>
                                    <Auth exact path="/" component={Home}/>
                                    <Auth path="/return-rate" component={ReturnRate}/>
                                    <Auth path="/transaction" component={Transaction}/>
                                    <Auth path="/order-list" component={OrderList}/>
                                    <Auth path="/running-order-list" component={RunningOrder}/>
                                    <Auth path="/set-result" component={Result}/>
                                    <Auth path="/previous-result" component={PreviousResult}/>
                                    <Auth path="/game-time" component={GameTime}/>
                                    <Auth path="/user-list" component={User}/>
                                    <Auth path="/admin-list" component={AdminList}/>
                                    <Auth path="/user-details/:id" component={UserData}/>
                                    <Auth path="/my-account" component={MyAccount}/>
                                    <Auth path="/user-transaction/:id" component={UserTransactionDetails}/>
                                    <Auth path="/user-orders/:id" component={UserOrderList}/>
                                    <Auth path="/payment-method-list" component={PaymentMethodList}/>
                                    <Auth path="/edit-payment-method/:id" component={EditPaymentMethodList}/>
                                    <Auth path="/category/" component={Category}/>
                                    <Auth path="/edit-category/:id" component={EditCategory}/>
                                    <Auth path="/area/" component={AreaList}/>
                                    <Auth path="/show-list/" component={ShowList}/>
                                    <Auth path="/edit-area/:id" component={EditArea}/>
                                    <Auth path="/add-user/" component={AddNewUser}/>
                                    <Auth path="/frsr-list/" component={FrSrList}/>
                                    <Auth path="/bet-calculate/" component={BetReturnAmount}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </BrowserRouter>
            }
        </div>
    );
}

export default App;
