import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import Pagination from "react-js-pagination";
import loader from './../../Assets/Images/loader/ripple.svg'
import Link from "react-router-dom/es/Link";
import Swal from "sweetalert2";

const RunningOrder = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [slFrom, setSlFrom] = useState(1)
    const [orders, setOrders] = useState([])
    const [total_bet_amount, set_total_bet_amount] = useState(null)
    const [total_return_amount, set_total_return_amount] = useState(null)

    const getOrders = (page_number = 1) => {
        setIsLoading(true)
        axios.post('/api/dashboard/get-orders?type=running&page=' + page_number, input).then(res => {
            setOrders(res.data.orders)
            setItemsCountPerPage(parseInt(50))
            setTotalItemsCount(res.data.total_orders)
            setActivePage(page_number)
            setSlFrom(1)
            setIsLoading(false)
            set_total_bet_amount(res.data.bet_amount)
            set_total_return_amount(res.data.return_amount)

        })
    }

    useEffect(() => {
        getOrders()
        getCategories()
       // showList()
    }, []);


    const [input, setInput] = useState({
        order: 'order',
    })
    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')
    const [showRoundList, setShowRoundList] = useState('none')
    const [roundAreaCss, setRoundAreaCss] = useState('none')

    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))


        if (e.target.name == 'category_id'){
            if (e.target.value == 1){
                setShowFrSr('none')
                setRoundAreaCss('none')
                showList()
                setShowRoundList('block')
                setInput(prevState => ({...prevState, round_show_id: 0, area_round_id:0}))
            }else if (e.target.value == 2){
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            }else if(e.target.value == 3){
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            }else if(e.target.value == 0){
                setShowRoundList('none')
                setRoundAreaCss('none')
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'round_show_id'){
            if (e.target.value != 0){
                getShowArea(e.target.value)
                setRoundAreaCss('block')
            }else{
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'area_round_id' && input.category_id == 2){
            if (e.target.value != 0){
                setShowFrSr('block')
            }else{
                setShowFrSr('none')
            }
        }

    }

    // const [show_id, set_show_id] = useState(null)
    const [round_areas, set_round_areas] = useState([])
    const getRoundArea = (id) => {
        axios.get('/api/dashboard/get-round-area/' + id).then(res => {
            set_round_areas(res.data)
        })
    }

    const getShowArea = (id) => {
        axios.get('/api/dashboard/get-round-list/1/' + id).then(res => {
            set_round_areas(res.data)
        })
    }


    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }

    const [refund,setRefund]=useState([

    ])
    const handleRefund = (e, id) => {


        Swal.fire({
            title: 'Are you sure to cancel?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel/'+id).then(res => {
                    getOrders()
                })
            }
        })


    }

    return (
        <>
            <Helmet>
                <title>Running Order List | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Running Order List</h5>
                        </div>
                        <div className="row align-items-end">
                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Category</label>
                                <select
                                    name={'category_id'}
                                    value={input.category_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Category</option>
                                    {categories.map((category, index) => (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-2" style={{display:showRoundList}}>
                                <label htmlFor="inputEmail3" className="col-form-label" >Select Show</label>
                                <select
                                    name={'round_show_id'}
                                    value={input.round_show_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Show</option>
                                    {showRound.map((showList, index) => (
                                        <option value={showList.id} key={index}>{showList.show_name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="col-md-2" style={{display:roundAreaCss}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select Round/Area</label>
                                <select
                                    name={'area_round_id'}
                                    value={input.area_round_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Area/Round</option>
                                    {round_areas.map((round_area, index) => (
                                        <option value={round_area.id} key={index}>{round_area.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: showFrSr}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select FR/SR</label>
                                <select
                                    name={'frsr_id'}
                                    value={input.frsr_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select FR/SR</option>
                                    {frSr.map((frSrData, index) => (
                                        <option value={frSrData.id} key={index}>{frSrData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Bet Calculate</label>
                                <select
                                    name={'order'}
                                    value={input.order}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={'order'}>Order Time</option>
                                    <option value={'return'}>Return Amount</option>
                                    <option value={'amount'}>Bet Amount</option>

                                </select>
                            </div>
                            <div className="col-md-2 custom-padding-top">
                                <button onClick={getOrders} className="btn btn-success">Search</button>
                            </div>
                            <div className="col-md-2">
                                <table className="order_amount_table">
                                    <tbody>
                                    <tr>
                                        <th className={'text-success'}>Bet</th>
                                        <td className={'text-success'}> : {total_bet_amount}</td>
                                    </tr>
                                    <tr>
                                        <th className={'text-info'}>Return</th>
                                        <td className={'text-info'}> : {total_return_amount}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="row mt-4  position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-12 table-responsive">

                                <table className="table table-hover table-striped table-bordered text-center">
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Time</th>
                                        <th>User</th>
                                        <th>Category</th>
                                        {/*<th>Show</th>*/}
                                        <th>Round/Area</th>
                                        <th>Number/Color</th>
                                        <th>Result Time</th>
                                        <th>Amount</th>
                                        <th>Return</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className={'text-center align-middle'}>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{order.time}</td>
                                            <td><Link to={`user-details/${order.user_id}`}>{order.user}</Link></td>
                                            <td>{order.category}</td>
                                            {/*<td>*/}
                                            {/*    {order.show_id==1? '1st Show'*/}
                                            {/*        :(order.show_id==2?'2nd Show'*/}
                                            {/*            :(order.show_id==3?'3rd Show'*/}
                                            {/*                :(order.show_id>3? `${order.show_id}th show`:null)))}*/}
                                            {/*</td>*/}
                                            <td>
                                                {order.frsr != null ?
                                                    <span className={'order-table-frsr-pill'}>{order.frsr}</span> : null
                                                }
                                                {order.show_id? <span className={'order-table-frsr-pill'}>{order.show_id[0]}</span>
                                                    :null }
                                                {order.round}
                                            </td>
                                            <td>
                                                <div className="order-table-number-flex">
                                                    <div className={'first'}>{order.investment_type} </div>

                                                    <div className={'third'}
                                                         style={order.status_id === 1 ?
                                                             {backgroundColor: '#e35252', color: 'white'}
                                                             : (order.status_id === 2 ? {
                                                                     backgroundColor: 'green',
                                                                     color: 'white'
                                                                 }
                                                                 : (order.status_id === 3 ? {
                                                                         backgroundColor: 'red',
                                                                         color: 'white'
                                                                     }
                                                                     : (order.status_id === 4 ? {
                                                                             backgroundColor: '#e35252',
                                                                             color: 'white'
                                                                         }
                                                                         : (order.status_id === 5 ? {
                                                                                 backgroundColor: 'green',
                                                                                 color: 'white'
                                                                             }
                                                                             : (order.status_id === 6 ? {
                                                                                 backgroundColor: 'red',
                                                                                 color: 'white'
                                                                             } : null)))))}
                                                    > {order.number}

                                                    </div>
                                                </div>
                                            </td>
                                            <td>{order.result_time}</td>
                                            <td
                                                style={order.status_id === 1 ?
                                                    {backgroundColor: '#e35252', color: 'white'}
                                                    : (order.status_id === 3 ? {
                                                        backgroundColor: 'red',
                                                        color: 'white'
                                                    } : null)}
                                            >{order.amount}</td>

                                            <td
                                                style={order.status_id === 2 ?
                                                    {backgroundColor: 'green', color: 'white'} : null}
                                            >{order.return_amount}</td>

                                            <td style={order.status_id === 1 ?
                                                {backgroundColor: '#e35252', color: 'white'}
                                                : (order.status_id === 2 ? {backgroundColor: 'green', color: 'white'}
                                                    : (order.status_id === 3 ? {backgroundColor: 'red', color: 'white'}
                                                        : (order.status_id === 4 ? {
                                                                backgroundColor: '#e35252',
                                                                color: 'white'
                                                            }
                                                            : (order.status_id === 5 ? {
                                                                    backgroundColor: 'green',
                                                                    color: 'white'
                                                                }
                                                                : (order.status_id === 6 ? {
                                                                    backgroundColor: 'red',
                                                                    color: 'white'
                                                                } : null)))))}>
                                               <p> {order.status}</p>

                                                {order.status_id != 6 ?
                                                    <button
                                                        className={'btn btn-info btn-sm'}
                                                        type={'button'}
                                                        onClick={(e)=>handleRefund(e,order.id )}
                                                    >
                                                        Cancel
                                                    </button>:null
                                                }
                                            </td>


                                        </tr>
                                    ))}

                                    </tbody>
                                </table>

                                {totalItemsCount > 20 ?
                                    <nav className={'pagination justify-content-end'}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={itemsCountPerPage}
                                            totalItemsCount={totalItemsCount}
                                            // pageRangeDisplayed={5}
                                            onChange={getOrders}
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassNext="page-item"
                                            itemClassPrev="page-item"
                                            itemClassFirst="page-item"
                                            itemClassLast="page-item"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                    </nav> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RunningOrder;
