import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import axios from "axios";

const FrSrList = () => {
    const [isLoading, setIsLoading] = useState(false)

    const [order, setOrder]= useState([])
    const getFrSrDataNew = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/frsr-list').then(res => {
            setOrder(res.data.data)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getFrSrDataNew()
    }, []);


    return (
        <>
            <Helmet>
                <title>FR/SR List | Teer-125</title>
            </Helmet>
            <div className="row mt-4 position-relative">
                {isLoading ?
                    <div className={'table-data-loader'}>
                        <img src={loader} alt={'Loader'}/>
                    </div>:null
                }
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Fr/Sr List</h5>
                        </div>
                        <div className="row mt-4  position-relative">
                            <div className="col-md-12 table-responsive">

                                <table className="table table-hover table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Name</th>
                                        <th>Time</th>
                                        <th>Area</th>
                                        <th>updated_at</th>
                                    </tr>
                                    </thead>
                                    <tbody className={'text-center'}>
                                    {order.map((orders, index) => (
                                        <tr key={index}>
                                            <td>{index+1}</td>
                                            <td>{orders.name}</td>
                                            <td>{orders.time}</td>
                                            <td>{orders.area}</td>
                                            <td>{orders.updated_at}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FrSrList;
