import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import $ from 'jquery'
import Swal from 'sweetalert2'
import {Button, Modal} from "react-bootstrap";
import Link from "react-router-dom/es/Link";
import {useHistory} from "react-router-dom";
import loader from "../../Assets/Images/loader/ripple.svg";

const GameTime = () => {
    const [errors , setErrors] = useState([])
    const [isLoading, setIsLoading]=useState(false)
// ------------------------------Add Round Start---------------------------
    const [Addshow, setAddShow] = useState(false);
    const addHandleClose = () => setAddShow(false);
    const addHandleShow = () => setAddShow(true);

    const [addDataRound, setAddDataRound] = useState({
        round_show_id:1,
        round_category_id:1
    })
    const roundHandleInputChange = (e) => {
        setAddDataRound(prevState => ({...prevState,[e.target.name]:e.target.value}))
        console.log(addDataRound)
    }
    const roundAddData = () => {
        setIsLoading(true)   
        axios.post('/api/dashboard/create-round',addDataRound).then(res => {
            showList()
            setAddShow(false)
            setAddDataRound({})
            setIsLoading(false)

        }).catch(errors =>{
            if (errors.response.status == 422){
                setErrors(errors.response.data.errors)
                setIsLoading(false)
            }
        })
    }

// ------------------------------Add Round End---------------------------

    const [categories, setCategories] = useState([])
    const getCategories = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
            setIsLoading(false)
        })
    }

    const [round_areas, set_round_areas] = useState([])
    const [category_id, set_category_id] = useState(null)

    const handleCategoryClick = (id) => {
        set_category_id(id)
        $(`#category_select_button_${id}`).addClass('btn-danger').removeClass('btn-outline-danger').siblings('.btn').removeClass('btn-danger').addClass('btn-outline-danger')
    }


    const [show_id, set_show_id] = useState(null)
    const handleShowChange = (id) => {
        set_show_id(id)
        $(`#show_select_button_${id}`).addClass('btn-danger').removeClass('btn-outline-danger').siblings('.btn').removeClass('btn-danger').addClass('btn-outline-danger')

    }


    useEffect(()=>{
        getRoundDataWithShow()
    }, [show_id])

    const getRoundDataWithShow = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-round-area-data-for-time-update/' + category_id+'/'+show_id).then(res => {
            set_round_areas([])
            set_round_areas(res.data.data)
            setIsLoading(false)
        })
    }


    useEffect(() => {
        if (category_id != 1){
            getRoundArea()
        }else{
            set_round_areas([])
        }
    }, [category_id])

    const getRoundArea = (load) => {
        if(!load){
        setIsLoading(true)
        }
        axios.get('/api/dashboard/get-round-area-data-for-time-update/' + category_id).then(res => {
            set_round_areas(res.data.data)
            if(!load){
                setIsLoading(false)
                }
        })
    }
    const empty = null;
    const [resultInput, setResultInput] = useState(empty)
    const handleResultInput = (e, round_id, frsr_id = null) => {
        setResultInput(prevState => ({
            ...prevState,
            frsr_id: frsr_id,
            category_id: category_id,
            time: e.target.value,
            round_id: round_id,
            show_id:show_id
        }))
        // console.log(resultInput)
    }

    const [round_id, setRoundId] = useState('')


    const saveResultInput = (round_id, frsr_id = null) => {
        axios.post('/api/dashboard/save-time', resultInput).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500,
            })
            getRoundArea(true);
        })

    }


    useEffect(() => {
        getCategories()
        showList()
    }, [])

    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }

// // Modal button Edit----------------------------
//     const [show, setShow] = useState(false);
//     const handleClose = () => setShow(false);
//     const handleShow = () => setShow(true);
//
// // Modal button Add----------------------------




    return (
        <>
            <Helmet>
                <title>Result | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Update Game Time</h5>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 text-center">
                                {categories.map((category, index) => (
                                    <button
                                        id={`category_select_button_${category.id}`}
                                            onClick={(e) => handleCategoryClick(category.id)}
                                            className=" btn btn-outline-danger ms-1">{category.name}</button>
                                ))}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>{round_areas.name}</h5>
                                {category_id == 1 ?
                                    <>
                                        <button onClick={addHandleShow} type={"button"}
                                                className={'add-new-btn-game-time'}>Add Round
                                        </button>
                                        <Link to={'show-list'} className={'show-list-game-time'}>Show List</Link>
                                    </> : null
                                }
                            </div>

                            {category_id == 1 ?
                                <div className="show-list-main text-center mt-4" id={'show_list_main'}>
                                    {showRound.map((showBtn, index) => (
                                        <button
                                            key={index}
                                            type={'button'}
                                            className=" btn btn-outline-danger m-1"
                                            onClick={()=>handleShowChange(showBtn.id)}
                                            id={`show_select_button_${showBtn.id}`}
                                        >
                                            {showBtn.show_name}
                                        </button>
                                    ))}
                                </div> : null
                            }

                            {/*{category_id == 1 ?*/}
                            {/*  :null*/}
                            {/*}*/}
                            {isLoading ?
                                    <div className="text-center">
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:null
                                }
                            {!isLoading && round_areas.round != null ? round_areas.round.map((round_area, index) => (
                                <div className="col-md-3 mt-4" key={index} >
                                    <div className="card">
                                        <div
                                            className="card-header custom-padding-card-header d-flex justify-content-between align-items-center">
                                            <h6 className={'text-uppercase'}>{round_area.name}</h6>
                                            <h6 className={'dashboard-time'}>{round_area.time}</h6>
                                            {/*<button onClick={handleShow} type={"button"}*/}
                                            {/*        className={'edit-btn-game-time dashboard-time'}>Edit*/}
                                            {/*</button>*/}
                                        </div>
                                        <div className="card-body">
                                            {/*<Countdown*/}
                                            {/*    date={round_area.expire ? Date.now() : round_area.raw_time}*/}
                                            {/*    // date={Date.now() + item.raw_time}*/}
                                            {/*    renderer={renderer}*/}
                                            {/*/>*/}
                                            <div className="input-group">
                                                <input
                                                    type={'time'}
                                                    className={'form-control form-control-sm'}
                                                    onChange={(e) => handleResultInput(e, round_area.id)}
                                                    name={'time'}
                                                    defaultValue={round_area.time_edit}
                                                    //value="08:56"
                                                />
                                                <button onClick={() => saveResultInput(round_area.id)}
                                                        className={'btn btn-sm btn-success input-group-text'}>Save Time
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            )) : null}

                            {!isLoading && round_areas.area != null ? round_areas.area.map((round_area, index) => (
                                <div className="col-md-6 mt-4" key={index}>
                                    <div className="card">
                                        <div className="card-header area-card-header-bg">
                                            <h5 className="text-uppercase">{round_area.name}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {round_area.frsr != undefined ? round_area.frsr.map((frsr, frsrind) => (
                                                    <div className="col-md-6">
                                                        <div className="card" key={frsrind}>
                                                            <div
                                                                className="card-header d-flex justify-content-between align-items-center">
                                                                <h6 className={'text-uppercase'}>{frsr.name}</h6>
                                                                <h6 className={'dashboard-time'}>{frsr.time}</h6>
                                                            </div>
                                                            <div className="card-body">
                                                                {/*<Countdown*/}
                                                                {/*    date={round_area.expire ? Date.now() : round_area.raw_time}*/}
                                                                {/*    // date={Date.now() + item.raw_time}*/}
                                                                {/*    renderer={renderer}*/}
                                                                {/*/>*/}

                                                                <div className="input-group">
                                                                    <input
                                                                        type={'time'}
                                                                        className={'form-control form-control-sm'}
                                                                        onChange={(e) => handleResultInput(e, round_area.id, frsr.id)}
                                                                        name={'result'}
                                                                        defaultValue={frsr.time_edit}
                                                                    />
                                                                    <button
                                                                        onClick={() => saveResultInput(round_area.id, frsr.id)}
                                                                        className={'btn btn-sm btn-success input-group-text'}>Save
                                                                        Time
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null}
                        </div>

                        {/*React Bootstrap Modal add Start */}
                        <Modal
                            show={Addshow}
                            onHide={addHandleClose}
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Add New Round</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form>
                                    <div className="mb-1">
                                        <label className="form-label">Select Show
                                            <select
                                                className="form-select"
                                                name={'round_show_id'}
                                                value={addDataRound.round_show_id}
                                                onChange={roundHandleInputChange}

                                            >
                                                {showRound.map((addShow, indexAdd)=>(
                                                    <option value={addShow.id} key={indexAdd}>{addShow.show_name}</option>
                                                ))}

                                            </select>
                                        </label>
                                    </div>

                                    <div className="mb-1">
                                        <label className="form-label">Round Name
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={'name'}
                                                value={addDataRound.name}
                                                onChange={roundHandleInputChange}
                                            />
                                            {errors.name != undefined ?
                                                <p className={'text-danger'}><small>{errors.name[0]}</small></p>:null
                                            }
                                        </label>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Category
                                            <select
                                                name={'round_category_id'}
                                                value={addDataRound.round_category_id}
                                                onChange={roundHandleInputChange}
                                                className="form-select"
                                            >
                                                <option value="1">Bet 25</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div className="mb-1">
                                        <label className="form-label">Set Time
                                            <input
                                                type={'time'}
                                                className={'form-control form-control-sm'}
                                                name={'time'}
                                                value={addDataRound.time}
                                                onChange={roundHandleInputChange}
                                            />
                                            {errors.time != undefined ?
                                                <p className={'text-danger'}><small>{errors.time[0]}</small></p>:null
                                            }
                                        </label>
                                    </div>
                                    <div className="save-change-btn text-center">
                                        <Button variant="primary" type={"button"} onClick={roundAddData}>
                                            Add
                                        </Button>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>
                        {/*React Bootstrap Modal End*/}
                    </div>
                </div>
            </div>

        </>
    );
};

export default GameTime;
