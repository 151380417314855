import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import $ from 'jquery'
import Swal from "sweetalert2";
import loader from "../../Assets/Images/loader/ripple.svg";


const ReturnRate = () => {

    const [input, setInput] = useState({})
    const [isLoading, setIsLoading]=useState(false)
    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'category_id') {
            if (e.target.value == 2) {
                setShowFrSr('block')
            } else {
                setShowFrSr('none')
            }
        }
    }

    useEffect(() => {
        getCategories()
    }, [input]);


    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const [numbers, setNumbers] = useState([])
    const getNumbers = () => {
        setIsLoading(true)
        axios.post('/api/dashboard/get-numbers', input).then(res => {
            setNumbers(res.data.data)
            setIsLoading(false)
        })
    }

    const saveReturnMinAmount = (e, id) => {
        let return_amount = $(`#amount_input_${id}`).val()
        let min_amount = $(`#min_input_${id}`).val()

        $(`#amount_input_${id}`).removeClass('is-invalid')
        $(`#min_input_${id}`).removeClass('is-invalid')

        if (return_amount.length < 1) {
            $(`#amount_input_${id}`).addClass('is-invalid')
        }


        if (min_amount.length < 1) {
            $(`#min_input_${id}`).addClass('is-invalid')
        }

        let input_data = {return_amount: return_amount, minimum_amount: min_amount}

        axios.post('/api/dashboard/update-return-min-amount/' + id, input_data).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: res.data.msg,
                toast: true,
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    const handleAmountInput = (e, id) => {

        const newState = numbers.map(obj =>
            obj.id === id ? {...obj, [e.target.name]: e.target.value} : obj
        );

        setNumbers(newState)
    }

    return (
        <>
            <Helmet>
                <title>Return Rate | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Return Rate</h5>
                        </div>
                        <div className="row align-items-end">
                            <div className="col-md-4">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Category</label>
                                <select
                                    name={'category_id'}
                                    value={input.category_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Category</option>
                                    {categories.map((category, index) => (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4" style={{display: showFrSr}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select FR/SR</label>
                                <select
                                    name={'frsr_id'}
                                    value={input.frsr_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select FR/SR</option>
                                    {frSr.map((frSrData, index) => (
                                        <option value={frSrData.id} key={index}>{frSrData.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4 custom-padding-top">
                                <button onClick={getNumbers} className="btn btn-success">Search</button>
                            </div>
                        </div>
                        {isLoading ?
                                    <div className="text-center">
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:
                            <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="number-container-rate-page">
                                    {numbers.map((number, index) => (
                                        <>
                                            {number.color != null ?
                                                <div key={index} className={`number-item number-item-${number.color}`} style={{color: number.color == 'Yellow' ? 'black' : 'white'}}>
                                                    <h3 className="number">{number.color}</h3>
                                                    <div className="d-flex justify-content-around">
                                                        <small>Rtn.</small>
                                                        <small>Min.</small>
                                                    </div>
                                                    <div className="d-flex">
                                                        <input className='me-1' id={`amount_input_${number.id}`} name={'return_amount'} onChange={(e) => handleAmountInput(e, number.id)}
                                                               value={number.return_amount} type={'number'}/>
                                                        <input id={`min_input_${number.id}`} name={'minimum_amount'} onChange={(e) => handleAmountInput(e, number.id)} value={number.minimum_amount}
                                                               type={'number'}/>
                                                    </div>
                                                    <button onClick={(e) => saveReturnMinAmount(e, number.id)} type="button">Save</button>
                                                </div> : null
                                            }
                                        </>

                                    ))}

                                </div>
                            </div>
                        </div>
                        }
                        {!isLoading ?
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="number-container-rate-page align-items-center">
                                    {numbers.map((number, index) => (
                                        <>
                                            {number.house_ending != null && number.house_ending_type == 1 ?
                                                <>
                                                    {number.house_ending == 0 ?
                                                        <div className="house-ending-header">
                                                            <h4>House</h4>
                                                        </div>:null
                                                    }

                                                    <div key={index} className={`number-item number-item-${number.house_ending}`}>
                                                        <h3 className="number">{number.house_ending}</h3>
                                                        <div className="d-flex justify-content-around">
                                                            <small>Rtn.</small>
                                                            <small>Min.</small>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input className='me-1' id={`amount_input_${number.id}`} name={'return_amount'} onChange={(e) => handleAmountInput(e, number.id)}
                                                                   value={number.return_amount} type={'number'}/>
                                                            <input id={`min_input_${number.id}`} name={'minimum_amount'} onChange={(e) => handleAmountInput(e, number.id)} value={number.minimum_amount}
                                                                   type={'number'}/>
                                                        </div>
                                                        <button onClick={(e) => saveReturnMinAmount(e, number.id)} type="button">Save</button>
                                                    </div>
                                                </> : null
                                            }
                                        </>

                                    ))}

                                </div>
                            </div>
                        </div>
                        : null}
                        {!isLoading ?
                        <div className="row mt-4 align-items-center">
                            <div className="col-md-12">
                                <div className="number-container-rate-page align-items-center">
                                    {numbers.map((number, index) => (
                                        <>
                                            {number.house_ending != null && number.house_ending_type == 2 ?
                                                <>
                                                    {number.house_ending == 0 ?
                                                        <div className="house-ending-header">
                                                            <h4>Ending</h4>
                                                        </div>:null
                                                    }

                                                    <div key={index} className={`number-item number-item-${number.house_ending}`}>
                                                        <h3 className="number">{number.house_ending}</h3>
                                                        <div className="d-flex justify-content-around">
                                                            <small>Rtn.</small>
                                                            <small>Min.</small>
                                                        </div>
                                                        <div className="d-flex">
                                                            <input className='me-1' id={`amount_input_${number.id}`} name={'return_amount'} onChange={(e) => handleAmountInput(e, number.id)}
                                                                   value={number.return_amount} type={'number'}/>
                                                            <input id={`min_input_${number.id}`} name={'minimum_amount'} onChange={(e) => handleAmountInput(e, number.id)} value={number.minimum_amount}
                                                                   type={'number'}/>
                                                        </div>
                                                        <button onClick={(e) => saveReturnMinAmount(e, number.id)} type="button">Save</button>
                                                    </div>
                                                </> : null
                                            }
                                        </>

                                    ))}

                                </div>
                            </div>
                        </div>
                        : null}
                        {!isLoading ?
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="number-container-rate-page">
                                    {numbers.map((number, index) => (
                                        <>
                                            {number.number != null ?
                                                <div className={'number-item'} key={index}>
                                                    <h2 className="number">{number.number}</h2>
                                                    <div className="d-flex justify-content-around">
                                                        <small>Rtn.</small>
                                                        <small>Min.</small>
                                                    </div>
                                                    <div className="d-flex">
                                                        <input className='me-1' id={`amount_input_${number.id}`} name={'return_amount'} onChange={(e) => handleAmountInput(e, number.id)}
                                                               value={number.return_amount} type={'number'}/>
                                                        <input id={`min_input_${number.id}`} name={'minimum_amount'} onChange={(e) => handleAmountInput(e, number.id)} value={number.minimum_amount}
                                                               type={'number'}/>
                                                    </div>
                                                    <button onClick={(e) => saveReturnMinAmount(e, number.id)} type="button">Save</button>
                                                </div> : null
                                            }
                                        </>

                                    ))}

                                </div>
                            </div>
                        </div>
                        : null}


                    </div>
                </div>
            </div>

        </>
    );
};

export default ReturnRate;
