import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import loader from "../../Assets/Images/loader/ripple.svg";
import Swal from "sweetalert2";



const MyAccount = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [admin, setAdmin] = useState([])
    const [passwordInput , setPasswordInput] = useState({})
    

    const getData = () => {
        
        setIsLoading(true)
        axios.post('/api/dashboard/admin-info').then(res => {
            setAdmin(res.data)
            setIsLoading(false)
        })
    }

    const handlePasswordInput = (e, pass) => {
        setPasswordInput(prevState => ({...prevState, [e.target.name] : e.target.value}))
      }

      const updatePassword = () => {
        axios.post('/api/dashboard/admin-change-password', passwordInput).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 2000
            })
            setPasswordInput({password:'', password_confirmation:''})
        })
      }

    const changePasswordBtn = () => {
        updatePassword()
      }

    useEffect(() => {
        getData()
    }, []);

    return (
        <>
            <Helmet>
                <title>Account Details | Teer-125</title>
            </Helmet>
            <div className="row mt-4 justify-content-center">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>User Details</h5>
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-6">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4>Personal Information</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-hover table-bordered table-striped">
                                                <tbody>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>{admin.name}</th>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>{admin.email}</th>
                                                </tr>
                                                <tr>
                                                    <th>Phone</th>
                                                    <th>{admin.phone}</th>
                                                </tr>
                                                <tr>
                                                    <th>Username</th>
                                                    <th>{admin.username}</th>
                                                </tr>
                                                <tr>
                                                    <th>Registered at</th>
                                                    <th>{admin.created_at}</th>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card h-100">
                                    <div className="card-header">
                                        <h4>Account Information</h4>
                                    </div>
                                    <div className="card-body">                                         
                                        <div className={'password-section-main'}>
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <form>
                                                        <div className="pass-group mb-3 position-relative">
                                                            <label>New Password:</label>
                                                            <input
                                                                type="password"
                                                                id={'login_password_one'}
                                                                className="form-control pass-control"
                                                                placeholder="New Password"
                                                                name={'password'}
                                                                onChange={(e)=>handlePasswordInput(e, 'pass')}
                                                            />
                                                        </div>
                                                        <div className="pass-group mb-3 position-relative">
                                                            <label>Confirm Password:</label>
                                                            <input
                                                                type="password"
                                                                id={'login_password_two'}
                                                                className="form-control pass-control"
                                                                placeholder="Confirm Password"
                                                                name={'password_confirmation'}
                                                                onChange={(e)=>handlePasswordInput(e, 'pass')}
                                                            />
                                                        </div>
                                                        <div className="pass-change-button text-center">
                                                            <button type={'button'} onClick={changePasswordBtn} className={'change-password-btn'}>Change Password</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>

                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default MyAccount;
