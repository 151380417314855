import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import loader from "../../Assets/Images/loader/ripple.svg";
import Pagination from "react-js-pagination";

const PreviousResult = () => {

    useEffect(() => {
        getResults()
    }, []);

    const [results, setResults] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getResults = (page_number = 1) => {
        setIsLoading(true)
        axios.post('/api/dashboard/get-all-results?page=' + page_number, input).then(res => {
            setResults(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }
    // -----------------------------previous result start---------------------


    const [showRoundList, setShowRoundList] = useState('none')
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [slFrom, setSlFrom] = useState(1)
    const [roundAreaCss, setRoundAreaCss] = useState('none')
    useEffect(() => {
        getResults()
        getCategories()
        showList()
    }, []);


    const [input, setInput] = useState({})
    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')

    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))

        if (e.target.name == 'category_id') {
            if (e.target.value == 1) {
                setShowFrSr('none')
                setRoundAreaCss('none')
                showList()
                setShowRoundList('block')
                setInput(prevState => ({...prevState, round_show_id: 0, area_round_id: 0}))
            } else if (e.target.value == 2) {
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            } else if (e.target.value == 3) {
                setShowFrSr('none')
                getRoundArea(e.target.value)
                setShowRoundList('none')
                setRoundAreaCss('block')
            } else if (e.target.value == 0) {
                setShowRoundList('none')
                setRoundAreaCss('none')
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'round_show_id') {
            if (e.target.value != 0) {
                getShowArea(e.target.value)
                setRoundAreaCss('block')
            } else {
                setRoundAreaCss('none')
            }
        }

        if (e.target.name == 'area_round_id' && input.category_id == 2) {
            if (e.target.value != 0) {
                setShowFrSr('block')
            } else {
                setShowFrSr('none')
            }
        }

    }

    const getShowArea = (id) => {
        axios.get('/api/dashboard/get-round-list/1/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [round_areas, set_round_areas] = useState([])
    const getRoundArea = (id) => {
        axios.get('/api/dashboard/get-round-area/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }

    // -----------------------------previous result end---------------------

    return (
        <div>
            <Helmet>
                <title>Previous Result | Teer-125</title>
            </Helmet>

            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Previous Result</h5>
                        </div>
                        <div className="row mt-4">

                            {/*<div className="col-md-6 text-start">*/}
                            {/*    <button onClick={()=>handleDate('yesterday')} className={'btn btn-sm btn-success'}>Previous Day</button>*/}
                            {/*    <button onClick={()=>handleDate('today')} className={'btn btn-sm btn-success ms-1'}>Today</button>*/}
                            {/*</div>*/}
                            {/*<div className="col-md-6 text-end">*/}
                            {/*    <div className="row justify-content-end align-items-center">*/}
                            {/*        <div className="col-md-4">*/}
                            {/*            <p>Select Date</p>*/}
                            {/*        </div>*/}
                            {/*        <div className="col-md-4">*/}
                            {/*            <input*/}
                            {/*                type={'date'}*/}
                            {/*                className={'form-control form-control-sm'}*/}
                            {/*                name={'date'}*/}
                            {/*                onChange={handleDateInput}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                        </div>
                        <div className="row mt-4 align-items-end">
                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Category</label>
                                <select
                                    name={'category_id'}
                                    value={input.category_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select Category</option>
                                    {categories.map((category, index) => (
                                        <option value={category.id} key={index}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: showRoundList}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select Show</label>
                                <select
                                    name={'round_show_id'}
                                    value={input.round_show_id}
                                    onChange={handleInput}
                                    className="form-select"
                                >
                                    <option value={0}>Select Show</option>
                                    {showRound.map((showList, index) => (
                                        <option value={showList.id} key={index}>{showList.show_name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: roundAreaCss}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select Round/Area</label>
                                <select
                                    name={'area_round_id'}
                                    value={input.area_round_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select Area/Round</option>
                                    {round_areas.map((round_area, index) => (
                                        <option value={round_area.id} key={index}>{round_area.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-2" style={{display: showFrSr}}>
                                <label htmlFor="inputEmail3" className="col-form-label">Select FR/SR</label>
                                <select
                                    name={'frsr_id'}
                                    value={input.frsr_id}
                                    onChange={handleInput}
                                    className="form-select form-select-sm"
                                >
                                    <option value={0}>Select FR/SR</option>
                                    {frSr.map((frSrData, index) => (
                                        <option value={frSrData.id} key={index}>{frSrData.name}</option>
                                    ))}
                                </select>
                            </div>
                            {/*<div className="col-md-2">*/}
                            {/*    <label htmlFor="inputEmail3" className="col-form-label">Status</label>*/}
                            {/*    <select*/}
                            {/*        name={'status'}*/}
                            {/*        value={input.status}*/}
                            {/*        onChange={handleInput}*/}
                            {/*        className="form-select form-select-sm"*/}
                            {/*    >*/}
                            {/*        <option value={null}>Select Status</option>*/}
                            {/*        <option value={1}>Running</option>*/}
                            {/*        <option value={3}>Loss</option>*/}
                            {/*        <option value={2}>Win</option>*/}
                            {/*    </select>*/}
                            {/*</div>*/}

                            {/*<div className="col-md-2">*/}
                            {/*    <label htmlFor="inputEmail3" className="col-form-label">Amount</label>*/}
                            {/*    <select*/}
                            {/*        name={'order'}*/}
                            {/*        value={input.order}*/}
                            {/*        onChange={handleInput}*/}
                            {/*        className="form-select form-select-sm"*/}
                            {/*    >*/}
                            {/*        <option value={null}>Select Status</option>*/}
                            {/*        <option value={'desc'}>Bigger</option>*/}
                            {/*        <option value={'asc'}>Lower</option>*/}

                            {/*    </select>*/}
                            {/*</div>*/}

                            <div className="col-md-2">
                                <label htmlFor="inputEmail3" className="col-form-label">Select Date</label>
                                <input
                                    type={'date'}
                                    name={'date'}
                                    value={input.date}
                                    onChange={handleInput}
                                    className="form-control form-control-sm"
                                />
                            </div>
                            <div className="col-md-2 custom-padding-top">
                                <button onClick={getResults} className="btn btn-success btn-sm">Search</button>
                            </div>
                            {/*<div className="col-md-2">*/}
                            {/*    <table className="order_amount_table">*/}
                            {/*        <tbody>*/}
                            {/*        <tr>*/}
                            {/*            <th className={'text-success'}>Bet</th>*/}
                            {/*            <td className={'text-success'}> : {total_bet_amount}</td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <th className={'text-info'}>Return</th>*/}
                            {/*            <td className={'text-info'}> : {total_return_amount}</td>*/}
                            {/*        </tr>*/}
                            {/*        </tbody>*/}
                            {/*    </table>*/}
                            {/*</div>*/}
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <table className="table table-striped table-hover table-bordered">
                                        <thead>
                                        <tr>
                                            <th className={'text-center'}>SL</th>
                                            <th className={'text-center'}>Category</th>
                                            <th className={'text-center'}>Round/Area</th>
                                            <th className={'text-center'}>Time</th>
                                            <th className={'text-center'}>Winner Number</th>
                                            <th className={'text-center'}>Result Publish Time</th>
                                        </tr>
                                        </thead>

                                        <tbody>
                                        {results.map((result, index) => (
                                            <tr key={index}>
                                                <td className={'text-center'}>{slFrom + index}</td>
                                                <td className={'text-center'}>{result.category}</td>
                                                <td className={'text-center'}>
                                                    {/*{result.round}*/}
                                                    {/*{result.area}*/}
                                                    {/*{result.frsr != null ?*/}
                                                    {/*    <span className={'ms-1 text-info'}>{result.frsr}</span> : null*/}
                                                    {/*}*/}
                                                    {result.frsr != null ?
                                                        <span className={'order-table-frsr-pill'}>{result.frsr}</span> : null
                                                    } {result.area}
                                                    {result.show ? <span className={'order-table-frsr-pill'}>{result.show}</span>
                                                        : null }
                                                    {result.round}
                                                </td>
                                                <td className={'text-center'}> {result.time}</td>
                                                <td className={'text-center'}> {result.winner_number}</td>
                                                <td className={'text-center'}>{result.created_at}</td>
                                            </tr>
                                        ))}

                                        </tbody>

                                    </table>

                                    {totalItemsCount > 20 ?
                                        <nav className={'pagination justify-content-end'}>
                                            <Pagination
                                                activePage={activePage}
                                                itemsCountPerPage={itemsCountPerPage}
                                                totalItemsCount={totalItemsCount}
                                                // pageRangeDisplayed={5}
                                                onChange={getResults}
                                                nextPageText="Next"
                                                prevPageText="Previous"
                                                itemClass="page-item"
                                                linkClass="page-link"
                                                itemClassNext="page-item"
                                                itemClassPrev="page-item"
                                                itemClassFirst="page-item"
                                                itemClassLast="page-item"
                                                firstPageText="First"
                                                lastPageText="Last"
                                            />
                                        </nav> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default PreviousResult;
