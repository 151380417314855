import React, {useEffect, useState} from "react";
import {Redirect, Route, useHistory} from "react-router-dom";
import axios from "axios";

const liveAPI = true;
{liveAPI ? axios.defaults.baseURL = 'https://www.api.teer125.com' : axios.defaults.baseURL = 'http://localhost:8000'}

const token = localStorage.admin_token
if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}


const Auth = ({component: Component, ...rest}) => {
    const history = useHistory()
    const [auth, setAuth] = useState(false);

    const token = localStorage.admin_token
    return (
        <Route
            {...rest}
            render={props =>
                token != undefined ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: props.location}
                        }}
                    />
                )
            }
        />
    )
}
export default Auth
