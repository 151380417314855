import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import $ from 'jquery'
import loader from "../../Assets/Images/loader/ripple.svg";

const Transaction = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [slFrom, setSlFrom] = useState(1)

    const [transactions, setTransactions] = useState([])
    const getTransaction = (page_number = 1) => {
        setIsLoading(true)
        axios.get(`/api/dashboard/get-transactions?page=${page_number}&status=${transaction_status}&type=${transaction_type}` ).then(res => {
            setTransactions(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }


    useEffect(() => {
        getTransaction()
    }, []);

    const [approveModalShow, setApproveModalShow] = useState(false)
    const [transactionInput, setTransactionInput] = useState({})
    const [transaction_id, set_transaction_id] = useState({})
    const handleApproveModal = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        if (approveModalShow) {
            setApproveModalShow(false)
        } else {
            if (id != null) {
                setTransactionInput(prevState => ({...prevState, transaction_id: transaction_id, amount: amount, payment_method_id: payment_method_id, account_no: account_no}))
                set_transaction_id(id)
            }
            setApproveModalShow(true)
        }
    }
    const [withdrawApproveModalShow, setWithdrawApproveModalShow] = useState(false)
    const [withdrawTransactionInput, setWithdrawTransactionInput] = useState({})
    const handleWithdrawApproveModal = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {


        if (withdrawApproveModalShow) {
            setWithdrawApproveModalShow(false)
        } else {
            if (id != null) {
                setWithdrawTransactionInput(prevState => ({...prevState, transaction_id: transaction_id, amount: amount, payment_method_id: payment_method_id, account_no: account_no}))
                set_transaction_id(id)
            }
            setWithdrawApproveModalShow(true)
        }
    }
    const handleWithdrawDisApprove = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "Approval Request will be canceled",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel-approve-withdraw-request/' + id).then(res => {
                    if (res.data.status == 'fail') {
                        setError(res.data.msg)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        getTransaction()
                    }
                })
            }
        })
    }
    const handleCancelApprove = (id = null, transaction_id = null, amount = null, payment_method_id = null, account_no = null) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "Approval Request will be canceled",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/dashboard/cancel-approve-request/' + id).then(res => {
                    if (res.data.status == 'fail') {
                        setError(res.data.msg)
                    } else {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            toast: true,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        getTransaction()
                    }
                })
            }
        })
    }
    const handleWithdrawTransactionInput = (e) => {
        setWithdrawTransactionInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handleTransactionInput = (e) => {
        setTransactionInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const [error, setError] = useState(null)
    const handleWithdrawTransactionApprove = () => {
        setError(null)
        console.log(withdrawTransactionInput)
        axios.post('/api/dashboard/approve-withdraw-request/' + transaction_id, withdrawTransactionInput).then(res => {
            if (res.data.status == 'fail') {
                setError(res.data.msg)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                handleWithdrawApproveModal()
                getTransaction()
            }
        })
    }
    const handleTransactionApprove = () => {
        setError(null)
        axios.post('/api/dashboard/approve-transaction/' + transaction_id, transactionInput).then(res => {
            if (res.data.status == 'fail') {
                setError(res.data.msg)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    toast: true,
                    showConfirmButton: false,
                    timer: 1500
                })
                handleApproveModal()
                getTransaction()
            }
        })
    }
    const [payment_methods, set_payment_methods] = useState([])
    const getPaymentMethods = () => {
        axios.get('/api/dashboard/get-payment-methods').then(res => {
            set_payment_methods(res.data)
        })
    }

    useEffect(() => {
        getPaymentMethods()
    }, [])


    const [transaction_type, setTransaction_type] = useState(2)
    const handleTransactionType = (e, status) => {
        $(`#type_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransaction_type(status)
        setTransactionStatus(0)
    }

    const [transaction_status, setTransactionStatus] = useState(0)
    const handleTransactionStatus = (e, status) => {
        $(`#status_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransactionStatus(status)
    }

    useEffect(()=>{
        getTransaction()
    }, [transaction_status, transaction_type])

    return (
        <>
            <Helmet>
                <title>Transaction List | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Transaction List</h5>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 mt-2">
                                <div className="button-transaction text-center">
                                    {/* <button onClick={(e) => handleTransactionType(e, '0')} id={'type_button_0'} className={'transaction-btn'} type={'button'}>All</button> */}
                                    <button onClick={(e) => handleTransactionType(e, '1')} id={'type_button_1'} className={'transaction-btn'} type={'button'}>Bet</button>
                                    <button onClick={(e) => handleTransactionType(e, '2')} id={'type_button_2'} className={'transaction-btn transaction-btn-active'} type={'button'}>Add Fund</button>
                                    <button onClick={(e) => handleTransactionType(e, '3')} id={'type_button_3'} className={'transaction-btn'} type={'button'}>Withdraw</button>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-2">
                                <div className="button-transaction text-center">
                                    {/* <button onClick={(e) => handleTransactionStatus(e, '0')} id={'status_button_0'} className={'transaction-btn'} type={'button'}>All</button> */}
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '1')} id={'status_button_1'} className={'transaction-btn'} type={'button'}>Running</button> : null}
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '2')} id={'status_button_2'} className={'transaction-btn'} type={'button'}>Win</button> : null}
                                    {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '3')} id={'status_button_3'} className={'transaction-btn'} type={'button'}>Loss</button> : null}
                                    {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '4')} id={'status_button_4'} className={'transaction-btn'} type={'button'}>Pending</button> : null}
                                    {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '5')} id={'status_button_5'} className={'transaction-btn'} type={'button'}>Approved</button> : null}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div> : null
                            }
                            <div className="col-md-12 table-responsive">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="text-center">SL</th>
                                        <th className="text-center">User</th>
                                        <th className="text-center">Time</th>
                                        <th className="text-center">Type</th>
                                        <th className="text-center">Method</th>
                                        <th className="text-center">Transaction ID</th>
                                        <th className="text-center">Account No</th>
                                        <th className="text-center">Amount</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {transactions.map((transaction, index) => (
                                        <tr key={index}>
                                            <td className="align-middle text-center">{slFrom + index}</td>
                                            <td className="align-middle text-center"><Link to={`user-details/${transaction.user_id}`}> {transaction.user} </Link></td>
                                            <td className="align-middle text-center">{transaction.time}</td>
                                            <td className="align-middle text-center">{transaction.type}</td>
                                            <td className="align-middle text-center">

                                                <small>
                                                    <p>{transaction.category}</p>
                                                    <p>{transaction.round} {transaction.frsr != null ? <span class="order-table-frsr-pill ">{transaction.frsr}</span> : null}</p>
                                                    <p>{transaction.investment_type} {transaction.number != null ? <span class="order-table-number-pill" style={transaction.number == 'Green' ? {backgroundColor: 'green', color: 'white'} : transaction.number == 'Red' ? {backgroundColor: 'red', color: 'white'} : transaction.number == 'Yellow' ? {backgroundColor: 'yellow', color: 'black'} : null}>{transaction.number}</span> : null}</p>
                                                </small>

                                                {transaction.method}
                                            </td>
                                            <td className="align-middle text-center">{transaction.transaction_id}</td>
                                            <td className="align-middle text-center">{transaction.account_no}</td>
                                            <td className="align-middle text-center">{transaction.amount}</td>
                                            <td className="align-middle text-center">{transaction.status}</td>
                                            <td className="align-middle text-center">
                                                <div style={{width: '247px'}}>
                                                    <Link to={`user-details/${transaction.user_id}`}>
                                                        <button className="btn btn-sm btn-info me-1"><i className="fas fa-eye"/> View</button>
                                                    </Link>
                                                    {transaction.type == 'Add Fund' && transaction.status == 'Pending' ?
                                                        <>
                                                            <button
                                                                onClick={() => handleCancelApprove(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                                className="btn btn-sm btn-warning  me-1"><i
                                                                className="fas fa-times"/> Cancel
                                                            </button>
                                                            <button
                                                                onClick={() => handleApproveModal(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                                className="btn btn-sm btn-warning"><i
                                                                className="fas fa-check me-1"/> Approve
                                                            </button>
                                                        </>
                                                        : null
                                                    }

                                                    {transaction.type == 'Withdraw' && transaction.status == 'Pending' ?
                                                        <>
                                                            <button
                                                                onClick={() => handleWithdrawDisApprove(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                                className="btn btn-sm btn-warning  me-1"><i
                                                                className="fas fa-times"/> Cancel
                                                            </button>
                                                            <button
                                                                onClick={() => handleWithdrawApproveModal(transaction.id, transaction.transaction_id, transaction.amount, transaction.method_id, transaction.account_no)}
                                                                className="btn btn-sm btn-warning"><i
                                                                className="fas fa-check me-1"/> Approve
                                                            </button>
                                                        </>

                                                        : null
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                    </tbody>
                                </table>
                                {totalItemsCount > 20 ?
                                    <nav className={'pagination justify-content-end'}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={itemsCountPerPage}
                                            totalItemsCount={totalItemsCount}
                                            // pageRangeDisplayed={5}
                                            onChange={getTransaction}
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassNext="page-item"
                                            itemClassPrev="page-item"
                                            itemClassFirst="page-item"
                                            itemClassLast="page-item"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                    </nav> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={approveModalShow} onHide={handleApproveModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Approve Transaction
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        name={'transaction_id'}
                        value={transactionInput.transaction_id}
                        onChange={handleTransactionInput}
                    />
                    {error != null ?
                        <small className="text-danger">{error}</small> : null
                    }
                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'amount'}
                        value={transactionInput.amount}
                        onChange={handleTransactionInput}
                    />


                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'account_no'}
                        value={transactionInput.account_no}
                        onChange={handleTransactionInput}
                        placeholder={'Account Number'}
                    />


                    <select
                        className={'form-select mt-2'}
                        onChange={handleTransactionInput}
                        value={transactionInput.payment_method_id}
                        name={'payment_method_id'}
                        placeholder={'Enter Amount'}
                    >
                        <option value={0}>Select Payment Method</option>
                        {payment_methods.map((method, index) => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </select>
                    <div className="d-grid mt-3">
                        <button onClick={handleTransactionApprove} className="btn btn-success">Approve</button>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={withdrawApproveModalShow} onHide={handleWithdrawApproveModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Approve Withdraw
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <input
                        type="text"
                        className="form-control"
                        name={'transaction_id'}
                        value={withdrawTransactionInput.transaction_id}
                        onChange={handleWithdrawTransactionInput}
                    />
                    {error != null ?
                        <small className="text-danger">{error}</small> : null
                    }
                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'amount'}
                        value={withdrawTransactionInput.amount}
                        onChange={handleWithdrawTransactionInput}
                    />


                    <input
                        type="text"
                        className="form-control mt-2"
                        name={'account_no'}
                        value={withdrawTransactionInput.account_no}
                        onChange={handleWithdrawTransactionInput}
                        placeholder={'Account Number'}
                    />

                    <select
                        className={'form-select mt-2'}
                        onChange={handleWithdrawTransactionInput}
                        value={withdrawTransactionInput.payment_method_id}
                        name={'payment_method_id'}
                        placeholder={'Enter Amount'}
                    >
                        <option value={0}>Select Payment Method</option>
                        {payment_methods.map((method, index) => (
                            <option value={method.id}>{method.name}</option>
                        ))}
                    </select>


                    <div className="d-grid mt-3">
                        <button onClick={handleWithdrawTransactionApprove} className="btn btn-success">Approve</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Transaction;
