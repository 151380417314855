import React, {useEffect, useState} from 'react';
// import {faArrowDown, faEye, faSearch} from "@fortawesome/free-solid-svg-icons";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {Link} from "react-router-dom";
import '@fortawesome/free-solid-svg-icons'
// import CommonTable from "./CommonTable";
import loader from "../../Assets/Images/loader/ripple.svg";
// import Pagination from "react-js-pagination";
import axios from "axios";
import Link from "react-router-dom/es/Link";


const RecentOrder = (props) => {


    const [isLoading, setIsLoading] = useState(false)
    // const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    // const [totalItemsCount, setTotalItemsCount] = useState(0)
    // const [activePage, setActivePage] = useState(1)
    const [slFrom, setSlFrom] = useState(1)
    const [orders, setOrders] = useState([])

    const getOrders = (page_number = 1) => {
        setIsLoading(true)
        axios.post('/api/dashboard/get-orders?page=' + page_number, input).then(res => {
            setOrders(res.data.orders)
            // setItemsCountPerPage(parseInt(res.data.meta.per_page))
            // setTotalItemsCount(res.data.meta.total)
            // setActivePage(res.data.meta.current_page)
            // setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getOrders()
        getCategories()
    }, []);


    const [input, setInput] = useState({})
    const [categories, setCategories] = useState([])
    // const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    // const [showFrSr, setShowFrSr] = useState('none')

    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    // const handleInput = (e) => {
    //     setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    //
    //     if (e.target.name == 'category_id') {
    //         getRoundArea(e.target.value)
    //         if (e.target.value == 2) {
    //             setShowFrSr('block')
    //         } else {
    //             setShowFrSr('none')
    //         }
    //     }
    // }

    // const [round_areas, set_round_areas] = useState([])
    // const getRoundArea = (id) => {
    //     axios.get('/api/dashboard/get-round-area/' + id).then(res => {
    //         set_round_areas(res.data)
    //     })
    // }


    return (
        <div className="row mt-4">
            <div className="col-md-12">
                <div className="top-selling-table-section">
                    <div className="top-selling-header">
                        <h5 className={'top-selling-product-name'}>Recent Order</h5>
                    </div>
                    <div className="row mt-4  position-relative">
                        {isLoading ?
                            <div className={'table-data-loader'}>
                                <img src={loader} alt={'Loader'}/>
                            </div>:null
                        }
                        <div className="col-md-12 table-responsive">

                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>SL</th>
                                    <th>Time</th>
                                    <th>User</th>
                                    <th>Category</th>
                                    <th>Round/Area</th>
                                    <th>Number/Color</th>
                                    <th>Result Time</th>
                                    <th>Amount</th>
                                    <th>Return</th>
                                    <th>Status</th>
                                    {/*<th>Action</th>*/}
                                </tr>
                                </thead>
                                <tbody>
                                {orders.slice(0,10).map((order, index) => (
                                    <tr key={index}>
                                        <td>{slFrom+index}</td>
                                        <td>{order.time}</td>
                                        <td><Link to={`user-details/${order.user_id}`}>{order.user}</Link></td>
                                        <td>{order.category}</td>
                                        <td>
                                            {order.frsr != null ?
                                                <span className={'order-table-frsr-pill'}>{order.frsr}</span> : null
                                            }
                                            {order.show_id? <span className={'order-table-frsr-pill'}>{order.show_id[0]}</span>
                                                :null }
                                            {order.round}
                                        </td>
                                        <td>
                                            <div className="order-table-number-flex">
                                                <div className={'first'}>{order.investment_type} </div>

                                                <div className={'third'}> {order.number}</div>
                                            </div>
                                        </td>
                                        <td>{order.result_time}</td>
                                        <td>{order.amount}</td>
                                        <td>{order.return_amount}</td>
                                        <td>{order.status}</td>
                                        {/*<td>Action</td>*/}
                                    </tr>
                                ))}

                                </tbody>
                            </table>
                            {/*{totalItemsCount > 20 ?*/}
                            {/*    <nav className={'pagination justify-content-end'}>*/}
                            {/*        <Pagination*/}
                            {/*            activePage={activePage}*/}
                            {/*            itemsCountPerPage={itemsCountPerPage}*/}
                            {/*            totalItemsCount={totalItemsCount}*/}
                            {/*            // pageRangeDisplayed={5}*/}
                            {/*            onChange={getOrders}*/}
                            {/*            nextPageText="Next"*/}
                            {/*            prevPageText="Previous"*/}
                            {/*            itemClass="page-item"*/}
                            {/*            linkClass="page-link"*/}
                            {/*            itemClassNext="page-item"*/}
                            {/*            itemClassPrev="page-item"*/}
                            {/*            itemClassFirst="page-item"*/}
                            {/*            itemClassLast="page-item"*/}
                            {/*            firstPageText="First"*/}
                            {/*            lastPageText="Last"*/}
                            {/*        />*/}
                            {/*    </nav> : null*/}
                            {/*}*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentOrder;
