import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Swal from "sweetalert2";
import loader from "../../Assets/Images/loader/ripple.svg";

const EditPaymentMethodList = () => {
    const params = useParams()
    const history = useHistory()
    const [editData, setEditData] = useState({})
    const [isLoading, setIsLoading] = useState()
    const getEditData = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/show-payment-method-number/' + params.id).then(res => {
            setEditData(res.data)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getEditData()
    }, [])
    const handleInputChange = (e) => {
        setEditData(prevState => ({...prevState, [e.target.name]: e.target.value}))
        console.log(editData)
    }
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const handleUpdate = () => {
        setIsUpdateLoading(true)
        axios.put('/api/dashboard/update-payment-method-number/' + params.id, editData).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: res.data.msg,
                showConfirmButton: false,
                timer: 3000,
                toast: true
            })
            setIsUpdateLoading(false)
            history.push('/payment-method-list')
        }).catch(errors => {
            if (errors.response.status == 422) {
                setErrors(errors.response.data.errors)
                setIsUpdateLoading(false)
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>Edit Payment Method List | Teer-125</title>
            </Helmet>
            <div className="row mt-4 position-relative">
                {isLoading ?
                    <div className={'table-data-loader'}>
                        <img src={loader} alt={'Loader'}/>
                    </div> : null
                }
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Edit Payment Method List</h5>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="payment-edit-form">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Name
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={'Enter Your Name'}
                                                            name={'name'}
                                                            value={editData.name}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.name != undefined ?
                                                            <p className={'text-danger'}><small>{errors.name[0]}</small>
                                                            </p> : null
                                                        }
                                                    </label>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Account Number
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name={'account_no'}
                                                            value={editData.account_no}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.account_no != undefined ?
                                                            <p className={'text-danger'}>
                                                                <small>{errors.account_no[0]}</small></p> : null
                                                        }
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label className="form-label">Country
                                                    <select
                                                        className="form-select"
                                                        name={'country_id'}
                                                        value={editData.country_id}
                                                        onChange={handleInputChange}
                                                    >
                                                        <option selected value={'1'}>Bangladesh</option>
                                                        <option value={'2'}>India</option>
                                                        <option value={'3'}>Bhutan</option>
                                                        <option value={'4'}>Nepal</option>
                                                        <option value={'5'}>Singapore</option>
                                                    </select>
                                                </label>

                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Status
                                                        <select
                                                            className="form-select"
                                                            name={'status'}
                                                            value={editData.status}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Order By
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name={'order_by'}
                                                            value={editData.order_by}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.order_by != undefined ?
                                                            <p className={'text-danger'}>
                                                                <small>{errors.order_by[0]}</small></p> : null
                                                        }
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="update-button text-center">
                                            <button type="button" className="btn btn-primary " onClick={handleUpdate}
                                                    dangerouslySetInnerHTML={{__html: isUpdateLoading ? '<div class="spinner-border spinner-border-sm"></div>' : 'Update'}}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default EditPaymentMethodList;


