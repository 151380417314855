import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import {Link} from "react-router-dom";
import axios from "axios";

const AreaList = () => {
    const [isLoading, setIsLoading]=useState(false)
    const [area, setArea]= useState([])
    const getPaymentMethodData = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/areas').then(res=>{
            setArea(res.data.data)
            setIsLoading(false)
        })
    }

    useEffect(()=>{
        getPaymentMethodData()
    }, [])
    return (
        <>
            <Helmet>
                <title>Area List | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Area List</h5>
                        </div>
                        <div className="row mt-4 position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div>:null
                            }
                            <div className="col-md-12 table-responsive">
                                <table className="table table-hover table-striped table-bordered">
                                    <thead>
                                    <tr>
                                        <th className="text-center">SL</th>
                                        <th className="text-center">Name</th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Order By</th>
                                        <th className="text-center">Category</th>
                                        <th className="text-center">Update Time</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {area.map((areas, index) => (
                                        <tr key={index}>
                                            <td className="text-center">{index + 1}</td>
                                            <td className="text-center">{areas.name}</td>
                                            <td className="text-center">{areas.status}</td>
                                            <td className="text-center">{areas.order_by}</td>
                                            <td className="text-center">{areas.category}</td>
                                            <td className="text-center">{areas.updated_at}</td>
                                            <td className="text-center">
                                                <Link to={`/edit-area/${areas.id}`}> <button type={'button'} className={'edit-button'}>Edit</button></Link>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AreaList;
