import React, {useEffect, useState} from 'react';
import axios from "axios";
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import Pagination from "react-js-pagination";
import {useParams} from "react-router-dom";

const UserOrderList = () => {

    const prams = useParams()
    const [showRoundList, setShowRoundList] = useState('none')
    const [isLoading, setIsLoading] = useState(false)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [orders, setOrders] = useState([])
    const [slFrom, setSlFrom] = useState(1)

    const getOrders = (page_number = 1) => {
        setIsLoading(true)
        axios.get('/api/dashboard/user-orders/'+prams.id+'?page=' + page_number).then(res => {
            setOrders(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)
            setSlFrom(res.data.meta.from)
            setIsLoading(false)
        })
    }

    useEffect(() => {
        getOrders()
        getCategories()
        showList()
    }, []);


    const [input, setInput] = useState({})
    const [categories, setCategories] = useState([])
    const [frSr, setFrSr] = useState([{id: 1, 'name': 'FR'}, {id: 2, 'name': 'SR'}])
    const [showFrSr, setShowFrSr] = useState('none')

    const getCategories = () => {
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
        if (e.target.name == 'category_id') {
            // getRoundArea(e.target.value)
            if (e.target.value == 1) {
                localStorage.setItem("category", 1)
                setShowRoundList('block')
            } else {
                setShowRoundList('none')
            }
        }
        if (e.target.name == 'category_id') {
            getRoundArea(e.target.value)
            if (e.target.value == 2) {
                localStorage.setItem("category", 2)
                setShowFrSr('block')
            } else {
                setShowFrSr('none')
            }
        }
        let category = localStorage.getItem("category")
        if(category == 1 && input.round_show_id != null){
            // console.log(e.target.value)
            if(e.target.name != 'area_round_id'){
                getShowArea(e.target.value)
            }
        }
    }
    const getShowArea = (id) => {
        axios.get('/api/dashboard/get-round-list/1/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [round_areas, set_round_areas] = useState([])
    const getRoundArea = (id) => {
        axios.get('/api/dashboard/get-round-area/' + id).then(res => {
            set_round_areas(res.data)
        })
    }
    const [showRound, setShowRound] = useState([])
    const showList = () => {
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
        })
    }

    return (
        <>
            <Helmet>
                <title>Order List | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>All Order List</h5>
                        </div>
                        <div className="row mt-4  position-relative">
                            {isLoading ?
                                <div className={'table-data-loader'}>
                                    <img src={loader} alt={'Loader'}/>
                                </div>:null
                            }
                            <div className="col-md-12 table-responsive">

                                <table className="table table-hover table-striped table-bordered text-center">
                                    <thead>
                                    <tr>
                                        <th>SL</th>
                                        <th>Time</th>
                                        <th>User</th>
                                        <th>Category</th>
                                        <th>Round/Area</th>
                                        <th>Number/Color</th>
                                        <th>Result Time</th>
                                        <th>Amount</th>
                                        <th>Return</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody className={'text-center'}>
                                    {orders.map((order, index) => (
                                        <tr key={index}>
                                            <td>{activePage * (index + 1)}</td>
                                            <td>{order.time}</td>
                                            <td>{order.user}</td>
                                            <td>{order.category}</td>
                                            <td>
                                                {order.frsr != null ?
                                                    <span className={'order-table-frsr-pill'}>{order.frsr}</span> : null
                                                }
                                                {order.round}
                                            </td>
                                            <td>
                                                <div className="order-table-number-flex">
                                                    <div className={'first'}>{order.investment_type} </div>

                                                    <div className={'third'}
                                                         style={order.status_id === 1?
                                                             {backgroundColor:'#e35252', color:'white'}
                                                             :(order.status_id===2?{backgroundColor:'green', color:'white'}
                                                                 :(order.status_id===3?{backgroundColor:'red', color:'white'}
                                                                     :(order.status_id===4?{backgroundColor:'#e35252', color:'white'}
                                                                         :(order.status_id===5?{backgroundColor:'green', color:'white'}
                                                                             :(order.status_id===6?{backgroundColor:'red', color:'white'}:null)))))}
                                                    > {order.number}

                                                    </div>
                                                </div>
                                            </td>
                                            <td>{order.result_time}</td>
                                            <td
                                                style={order.status_id === 1?
                                                    {backgroundColor:'#e35252', color:'white'}
                                                    :(order.status_id===3?{backgroundColor:'red', color:'white'}:null)}
                                            >{order.amount}</td>

                                            <td
                                                style={order.status_id === 2?
                                                    {backgroundColor:'green', color:'white'}:null}
                                            >{order.return_amount}</td>

                                            <td style={order.status_id === 1?
                                                {backgroundColor:'#e35252', color:'white'}
                                                :(order.status_id===2?{backgroundColor:'green', color:'white'}
                                                    :(order.status_id===3?{backgroundColor:'red', color:'white'}
                                                        :(order.status_id===4?{backgroundColor:'#e35252', color:'white'}
                                                            :(order.status_id===5?{backgroundColor:'green', color:'white'}
                                                                :(order.status_id===6?{backgroundColor:'red', color:'white'}:null)))))}>
                                                {order.status}
                                            </td>

                                        </tr>
                                    ))}

                                    </tbody>
                                </table>

                                {totalItemsCount > 20 ?
                                    <nav className={'pagination justify-content-end'}>
                                        <Pagination
                                            activePage={activePage}
                                            itemsCountPerPage={itemsCountPerPage}
                                            totalItemsCount={totalItemsCount}
                                            // pageRangeDisplayed={5}
                                            onChange={getOrders}
                                            nextPageText="Next"
                                            prevPageText="Previous"
                                            itemClass="page-item"
                                            linkClass="page-link"
                                            itemClassNext="page-item"
                                            itemClassPrev="page-item"
                                            itemClassFirst="page-item"
                                            itemClassLast="page-item"
                                            firstPageText="First"
                                            lastPageText="Last"
                                        />
                                    </nav> : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserOrderList;
