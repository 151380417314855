import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import $ from 'jquery'
import Swal from "sweetalert2";
import loader from "../../Assets/Images/loader/ripple.svg";
// import Countdown from "react-countdown";


const Result = () => {
    const [categories, setCategories] = useState([])
    const [isLoading, setIsLoading]=useState(false)
    const getCategories = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-categories').then(res => {
            setCategories(res.data)
            setIsLoading(false)
        })
    }

    const [round_areas, set_round_areas] = useState([])
    const [category_id, set_category_id] = useState(null)

    const handleCategoryClick = (id) => {
        set_category_id(id)
        $(`#category_select_button_${id}`).addClass('btn-danger').removeClass('btn-outline-danger').siblings('.btn').removeClass('btn-danger').addClass('btn-outline-danger')
    }
    const getRoundArea = () => {
        setIsLoading(true)        
        axios.get('/api/dashboard/get-round-area-data/' + category_id).then(res => {
            set_round_areas(res.data.data)
            setIsLoading(false)    
        })
    }
    useEffect(() => {
        if (category_id != 1) {
            getRoundArea()
        } else {
            set_round_areas([])
        }
    }, [category_id])

    const [resultInput, setResultInput] = useState({})
    const handleResultInput = (e, round_id, frsr_id = null) => {
        setResultInput(prevState => ({
            ...prevState,
            frsr_id: frsr_id,
            category_id: category_id,
            result: e.target.value, round_id: round_id
        }))
    }
    console.log(resultInput)

    // const [round_id, setRoundId] = useState('')

    const saveResultInput = (round_id, frsr_id = null) => {
        axios.post('/api/dashboard/save-result', resultInput).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    useEffect(() => {
        getCategories()
    }, [])
    useEffect(() => {
        getCategories()
        showList()
    }, [])

    const [showRound, setShowRound] = useState([])
    const showList = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/shows/').then(res => {
            setShowRound(res.data.data)
            setIsLoading(false)
        })
    }
    const [show_id, set_show_id] = useState(null)
    const handleShowChange = (id) => {
        set_show_id(id)
        $(`#show_select_button_${id}`).addClass('btn-danger').removeClass('btn-outline-danger').siblings('.btn').removeClass('btn-danger').addClass('btn-outline-danger')

    }
    useEffect(() => {
        getRoundDataWithShow()
    }, [show_id])
    const getRoundDataWithShow = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/get-round-area-data/' + category_id + '/' + show_id).then(res => {
            set_round_areas([])
            set_round_areas(res.data.data)
            setIsLoading(false)
        })
    }

    return (
        <>
            <Helmet>
                <title>Result | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Result</h5>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12 text-center">
                                {categories.map((category, index) => (
                                    <button
                                        id={`category_select_button_${category.id}`}
                                        onClick={(e) => handleCategoryClick(category.id)}
                                        className=" btn btn-outline-danger ms-1">{category.name}</button>
                                ))}
                            </div>
                        </div>                        
                        <div className="row mt-4">
                            <div className="top-selling-header">
                                <h5 className={'top-selling-product-name'}>{round_areas.name}</h5>
                            </div>
                            {category_id == 1 ?
                                <div className="show-list-main text-center mt-4" id={'show_list_main'}>
                                    {showRound.map((showBtn, index) => (
                                        <button
                                            key={index}
                                            type={'button'}
                                            className=" btn btn-outline-danger m-1"
                                            onClick={() => handleShowChange(showBtn.id)}
                                            id={`show_select_button_${showBtn.id}`}
                                        >
                                            {showBtn.show_name}
                                        </button>
                                    ))}
                                </div> : null
                            }
                            {isLoading ?
                                    <div className="text-center">
                                        <img src={loader} alt={'Loader'}/>
                                    </div>:null
                                }
                            {!isLoading && round_areas.round != null && round_areas.id == 1 ? round_areas.round.map((round_area, index) => (
                                <div className="col-md-3 mt-4" key={index}>

                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h6 className={'text-uppercase'}>{round_area.name}</h6>
                                            <h6 className={'dashboard-time'}>{round_area.time}</h6>
                                            {/*<h6 className={'dashboard-time'}>{round_area.result}</h6>*/}
                                        </div>
                                        <div className="card-body">
                                            {/*<Countdown*/}
                                            {/*    date={round_area.expire ? Date.now() : round_area.raw_time}*/}
                                            {/*    // date={Date.now() + item.raw_time}*/}
                                            {/*    renderer={renderer}*/}
                                            {/*/>*/}
                                            <div className="flex-result">
                                                <div className="input-group">
                                                    {/*<button className={'btn btn-sm btn-success'}>{round_area.result}</button>*/}
                                                    <input
                                                        type={'text'}
                                                        className={'form-control form-control-sm'}
                                                        onChange={(e) => handleResultInput(e, round_area.id)}
                                                        name={'result'}
                                                        defaultValue={round_area.result}
                                                    />
                                                    <button onClick={() => saveResultInput(round_area.id)} className={'btn btn-sm btn-success input-group-text'}>Save Result</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null}
                            {!isLoading && round_areas.round != null && round_areas.id == 3 ? round_areas.round.map((round_area, index) => (
                                <div className="col-md-3 mt-4" key={index}>
                                    <div className="card">
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h6 className={'text-uppercase'}>{round_area.name}</h6>
                                            <h6 className={'dashboard-time'}>{round_area.time}</h6>
                                            {/*<h6 className={'dashboard-time'}> {round_area.result} </h6>*/}
                                        </div>
                                        <div className="card-body">
                                            {/*<Countdown*/}
                                            {/*    date={round_area.expire ? Date.now() : round_area.raw_time}*/}
                                            {/*    // date={Date.now() + item.raw_time}*/}
                                            {/*    renderer={renderer}*/}
                                            {/*/>*/}
                                            <div className="input-group">
                                                <input
                                                    type={'text'}
                                                    className={'form-control form-control-sm'}
                                                    onChange={(e) => handleResultInput(e, round_area.id)}
                                                    name={'result'}
                                                    defaultValue={round_area.result}
                                                />

                                                <button onClick={() => saveResultInput(round_area.id)} className={'btn btn-sm btn-success input-group-text'}>Save Result</button>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            )) : null}

                            {!isLoading && round_areas.area != null ? round_areas.area.map((round_area, index) => (
                                <div className="col-md-6 mt-4" key={index}>
                                    <div className="card">
                                        <div className="card-header area-card-header-bg">
                                            <h5 className="text-uppercase">{round_area.name}</h5>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {round_area.frsr != undefined ? round_area.frsr.map((frsr, frsrind) => (
                                                    <div className="col-md-6">
                                                        <div className="card" key={frsrind}>
                                                            <div className="card-header d-flex justify-content-between align-items-center">
                                                                <h6 className={'text-uppercase'}>{frsr.name}</h6>
                                                                <h6 className={'dashboard-time'}>{frsr.time}</h6>
                                                            </div>
                                                            <div className="card-body">
                                                                {/*<Countdown*/}
                                                                {/*    date={round_area.expire ? Date.now() : round_area.raw_time}*/}
                                                                {/*    // date={Date.now() + item.raw_time}*/}
                                                                {/*    renderer={renderer}*/}
                                                                {/*/>*/}

                                                                <div className="input-group">
                                                                    <input
                                                                        type={'text'}
                                                                        className={'form-control form-control-sm'}
                                                                        onChange={(e) => handleResultInput(e, round_area.id, frsr.id)}
                                                                        name={'result'}
                                                                        defaultValue={frsr.result}
                                                                    />
                                                                    <button onClick={() => saveResultInput(round_area.id, frsr.id)} className={'btn btn-sm btn-success input-group-text'}>Save Result
                                                                    </button>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default Result;
