import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faBars, faChevronRight, faSearch} from "@fortawesome/free-solid-svg-icons";
import logo from '../../Assets/Images/logo.png'
import {Link} from "react-router-dom";
import $ from 'jquery';

const SideNavBar = () => {

    const subMenuShowHandler = (id, sub_menu_exists_or_not) => {
        let has_active_class = $('.menu-active')
        for (let j = 0; j <= has_active_class.length; j++) {
            $('.menu-active', [j]).removeClass('menu-active')
        }
        $('#' + id).children('.dashboard-item').children('.menu-icon-end').css('transform', 'rotate(90deg)')
        // $('#' + id).children('.nav-main-submenu').slideToggle('fast')
        let display_status = $('#' + id).css('display')
        if (display_status != 'none') {
            $('#' + id).addClass('menu-active')
            $('#' + id).children('.nav-main-submenu').slideDown('fast')
        } else {
            $('#' + id).children('.nav-main-submenu').slideUp('fast')
        }
        let element_id = id.split('_')
        let length = Object.keys(menus).length
        for (let i = 0; i < length; i++) {
            if (i != element_id[1]) {
                $('#mainMenu_' + i).children('.nav-main-submenu').slideUp('fast')

                let rotate = $('#mainMenu_' + i).children('.dashboard-item').children('.menu-icon-end').css('transform')
                if (rotate == 'matrix(6.12323e-17, 1, -1, 6.12323e-17, 0, 0)') {
                    $('#mainMenu_' + i).children('.dashboard-item').children('.menu-icon-end').css('transform', 'rotate(0deg)')
                }
            }
        }
        let width =  $(window).width()
        console.log(sub_menu_exists_or_not)
        if (sub_menu_exists_or_not == undefined){
            if (width < 576){
                $('#mobile_menu_show').slideUp();
            }
        }
    }

    const handleSubMenuClick = (id) => {
        let elements = $('.submenu-title')
        for (let i = 0; i < elements.length; i++) {
            $('.submenu-title', [i]).removeClass('submenu-active')
        }
        $('#' + id).addClass('submenu-active')

        let width =  $(window).width()
        if (width < 576){
            $('#mobile_menu_show').slideUp();
        }

    }

    const [menus] = useState([
        {
            name: 'Dashboard',
            path: '/',
            icon: 'faSearch',
        },
        {
            name: 'Transaction',
            path: '/transaction',
            icon: 'faSearch',
        },
        {
            name: 'Running Order',
            path: '/running-order-list',
            icon: 'faSearch',
        },
        {
            name: 'All Order',
            path: '/order-list',
            icon: 'faSearch',
        },
        {
            name: 'Bet Calculate',
            path: '/bet-calculate',
            icon: 'faSearch',
        },
        {
            name: 'Set Result',
            path: '/set-result',
            icon: 'faSearch',
        },
        {
            name: 'Previous Result',
            path: '/previous-result',
            icon: 'faSearch',
        },
        {
            name: 'Return Rate',
            path: '/return-rate',
            icon: 'faSearch',
        },
        {
            name: 'Game Time',
            path: '/game-time',
            icon: 'faSearch',
        },
        {
            name: 'Games List',
            path: '/',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'Category List',
                    path: '/category',
                    icon: 'faSearch',
                },
                {
                    name: '25 - Show List',
                    path: '/show-list',
                    icon: 'faSearch',
                },
                {
                    name: '100 - Area List',
                    path: '/area',
                    icon: 'faSearch',
                },
                {
                    name: '100 - FR/SR List',
                    path: '/frsr-list',
                    icon: 'faSearch',
                },
            ]
        },
        {
            name: 'User',
            path: '/',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'User List',
                    path: '/user-list',
                    icon: 'faSearch',
                },
                {
                    name: 'Admin List',
                    path: '/admin-list',
                    icon: 'faSearch',
                },
                // {
                //     name: 'Add New User ',
                //     path: '/add-new-user',
                //     icon: 'faSearch',
                // },
            ]
        },

        // {
        //     name: 'Ticket',
        //     path: '/',
        //     icon: 'faSearch',
        // },
        // {
        //     name: 'Settings',
        //     path: '/',
        //     icon: 'faSearch',
        // },
        //
        // {
        //     name: 'Announcement',
        //     path: '/order',
        //     icon: 'faSearch',
        //     subMenu: [
        //         {
        //             name: 'Add Announcement',
        //             path: '/',
        //             icon: 'faSearch',
        //         },
        //         {
        //             name: 'Announcement List',
        //             path: '/',
        //             icon: 'faSearch',
        //         },
        //     ]
        // },


        // {
        //     name: 'Blog',
        //     path: '/',
        //     icon: 'faSearch',
        // },


        // {
        //     name: 'Financial Report ',
        //     path: '/',
        //     icon: 'faSearch',
        // },

        // {
        //     name: 'Activity Log',
        //     path: '/',
        //     icon: 'faSearch',
        // },

        {
            name: 'Payment Method List',
            path: '/payment-method-list',
            icon: 'faSearch',
        },
        {
            name: 'My Account',
            path: '/my-account',
            icon: 'faSearch',
        },

    ])

    // const handleMainMenuClick = () =>{
    //     return menu.subMenu !=undefined && Object.keys(menu.subMenu).length > 0 ?  false:  true
    // }
    const [searchTerm, setSearchTerm] = useState("")
    return (
        <>

            <div className="navbar-section-main" id={'mobile_menu_show'}>
                <div className="navbar-top-icon">
                    <img src={logo} className={'img-fluid rounded-logo'} alt="logo"/>
                </div>
                <div className="navbar-search-section">
                    <form>
                        <input type={'text'} className={'input-search-box'} placeholder="Search" onChange={event => {
                            setSearchTerm(event.target.value)
                        }}/>
                        <FontAwesomeIcon icon={faSearch} className={'top-search-icon-nav'}/>
                    </form>
                </div>

                <div className="nav-menu-section pb-5">
                    {menus.filter((menu => {
                        if (searchTerm == "") {
                            return menu
                        } else if (menu.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return menu
                        }
                    })).map((menu, index) => (
                        <div className="nav-main-menu" id={`mainMenu_${index}`}
                             onClick={() => subMenuShowHandler(`mainMenu_${index}`, menu.subMenu)} key={index}>
                            <Link to={menu.path}
                                  onClick={menu.subMenu != undefined && Object.keys(menu.subMenu).length > 0 ? (event) => event.preventDefault() : null}
                                  className={'dashboard-item'}>
                                <div className="nav-menu-item-inner">
                                    <FontAwesomeIcon icon={faBars} className={'me-2'}/>
                                    {menu.name}
                                </div>
                                {menu.subMenu != undefined ?
                                    <FontAwesomeIcon icon={faChevronRight} className={'menu-icon-end'}/> : null}
                            </Link>
                            {menu.subMenu != undefined ?
                                <div className="nav-main-submenu" style={{display: 'none'}}>

                                    {menu.subMenu.map((submenu, subIndex) => (
                                        <Link to={submenu.path} className={'submenu-title'}
                                              onClick={() => handleSubMenuClick('submenu_title_' + subIndex + index)}
                                              id={'submenu_title_' + subIndex + index} key={subIndex}>
                                            <div className="submenu">
                                                <FontAwesomeIcon icon={faArrowRight} className={'me-2'}/>
                                                {submenu.name}
                                            </div>
                                        </Link>
                                    ))}

                                </div> : null
                            }
                        </div>
                    ))}
                </div>
            </div>

        </>
    );
};

export default SideNavBar;
