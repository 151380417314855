import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import axios from "axios";
import Swal from "sweetalert2";
import Link from "react-router-dom/es/Link";


const AddNewUser = () => {
    const [errors, setErrors] = useState([])
    const [addUser, setaddUser] = useState({})
    const handleInputChange = (e) => {
        setaddUser(prevState => ({...prevState, [e.target.name]: e.target.value}))
        console.log(addUser)
    }

    const userSubmitData = () => {
        axios.post('/api/user-register',addUser).then(res => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500,
            })
          addUser({})

        }).catch(errors =>{
            if (errors.response.status == 422){
                setErrors(errors.response.data.errors)
            }
        })
    }

    return (

        <>
            <Helmet>
                <title>Add New User | Teer-125</title>
            </Helmet>
            <div className="row mt-4">
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Add New User</h5>
                        </div>
                        <div className="add-new-user-form">
                            <form>
                                <div className="row justify-content-center">
                                    <div className="col-md-5">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Name
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name={'name'}
                                                            value={addUser.name}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    {/*{errors.name != undefined ?*/}
                                                    {/*    <p className={'text-danger'}><small>{errors.name[0]}</small></p>:null*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Email
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            name={'email'}
                                                            value={addUser.email}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    {/*{errors.slug != undefined ?*/}
                                                    {/*    <p className={'text-danger'}><small>{errors.slug[0]}</small></p>:null*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Pasword
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            name={'password'}
                                                            value={addUser.password}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    {/*{errors.slug != undefined ?*/}
                                                    {/*    <p className={'text-danger'}><small>{errors.slug[0]}</small></p>:null*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Phone
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name={'phone'}
                                                            value={addUser.phone}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    {/*{errors.name != undefined ?*/}
                                                    {/*    <p className={'text-danger'}><small>{errors.name[0]}</small></p>:null*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">User Name
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name={'username'}
                                                            value={addUser.username}
                                                            onChange={handleInputChange}
                                                        />
                                                    </label>
                                                    {/*{errors.slug != undefined ?*/}
                                                    {/*    <p className={'text-danger'}><small>{errors.slug[0]}</small></p>:null*/}
                                                    {/*}*/}
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-2">
                                                    <label className="form-label">Country
                                                        <select
                                                            className="form-select"
                                                            name={'country_id'}
                                                            value={addUser.country_id}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option  selected >Select Your Country</option>
                                                            <option  value={'1'}>Bangladesh</option>
                                                            <option value={'2'}>India</option>
                                                            <option value={'3'}>Bhutan</option>
                                                            <option value={'4'}>Nepal</option>
                                                            <option value={'5'}>Singapore</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="new-user-btn text-center">
                                    <button onClick={userSubmitData} className={'add-new-btn-game-time custom-position'} type={"button"}>Add New User</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddNewUser;
