import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import loader from "../../Assets/Images/loader/ripple.svg";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const EditArea = () => {
    const params  = useParams()
    const history = useHistory()
    const [editData,setEditData]=useState({})
    const [isLoading, setIsLoading]=useState()
    const getEditData = () => {
        setIsLoading(true)
        axios.get('/api/dashboard/areas/'+params.id).then(res=>{
            setEditData(res.data)
            setIsLoading(false)
        })
    }

    const [categories , setCategory] = useState([])
    const getCategoryData = () => {
        axios.get('/api/dashboard/all-categories').then(res=>{
            setCategory(res.data.data)
        })
    }

    useEffect(()=>{
        getEditData()
        getCategoryData()
    }, [])
    const handleInputChange = (e) => {
        setEditData(prevState => ({...prevState,[e.target.name]:e.target.value}))
    }
    const [isUpdateLoading , setIsUpdateLoading] = useState(false)
    const [errors , setErrors] = useState([])
    const handleUpdate = () => {
        setIsUpdateLoading(true)
        axios.put('/api/dashboard/areas/'+params.id, editData).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: res.data.msg,
                showConfirmButton: false,
                timer: 3000,
                toast:true
            })
            setIsUpdateLoading(false)
            history.push('/area')
        }).catch(errors =>{
            if (errors.response.status == 422){
                setErrors(errors.response.data.errors)
                setIsUpdateLoading(false)
            }
        })
    }
    return (
        <>
            <Helmet>
                <title>Edit Area List | Teer-125</title>
            </Helmet>
            <div className="row mt-4 position-relative">
                {isLoading ?
                    <div className={'table-data-loader'}>
                        <img src={loader} alt={'Loader'}/>
                    </div>:null
                }
                <div className="col-md-12">
                    <div className="top-selling-table-section">
                        <div className="top-selling-header">
                            <h5 className={'top-selling-product-name'}>Edit AreaList</h5>
                        </div>
                        <div className="row mt-4">
                            <div className="col-md-12">
                                <div className="payment-edit-form">
                                    <form>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">
                                                        Name
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder={'Enter Your Name'}
                                                            name={'name'}
                                                            value={editData.name}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.name != undefined ?
                                                            <p className={'text-danger'}><small>{errors.name[0]}</small></p>:null
                                                        }
                                                    </label>

                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Category Id
                                                        <select
                                                            className={'form-select'}
                                                            name={'category_id'}
                                                            value={editData.category_id}
                                                            onChange={handleInputChange}
                                                        >
                                                            {categories.map((category, index)=>(
                                                                <option key={index} value={category.id}>{category.name}</option>
                                                            ))}
                                                        </select>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Status
                                                        <select
                                                            className="form-select"
                                                            name={'status'}
                                                            value={editData.status}
                                                            onChange={handleInputChange}
                                                        >
                                                            <option value="1">Active</option>
                                                            <option value="0">Inactive</option>
                                                        </select>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="mb-3">
                                                    <label className="form-label">Order By
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name={'order_by'}
                                                            value={editData.order_by}
                                                            onChange={handleInputChange}
                                                        />
                                                        {errors.order_by != undefined ?
                                                            <p className={'text-danger'}><small>{errors.order_by[0]}</small></p>:null
                                                        }
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="update-button text-center">
                                            <button type="button" className="btn btn-primary " onClick={handleUpdate} dangerouslySetInnerHTML={{__html: isUpdateLoading ? '<div class="spinner-border spinner-border-sm"></div>' : 'Update'}}/>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EditArea;
